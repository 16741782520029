import { combineReducers } from '@reduxjs/toolkit';
import { quote } from 'store/features/quote';
import { search } from 'store/features/search';
import { supplierSearch } from 'store/features/supplierSearch';
import { paymentMethod } from '../../features/paymentsMethod/model/paymentMethodSlice';

export const features = combineReducers({
  paymentMethod,
  search,
  supplierSearch,
  quote,
});
