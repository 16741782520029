import { useMedia, useSnackbar } from '@partstech/ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useSupplierSelector } from 'components/SupplierSelector/SupplierSelectorContext';
import { DEFAULT_AVAILABILITY } from 'constant';
import { useGetSearchUrl } from '../search/useGetSearchUrl';
import { useHasCatalog } from '../search/useHasCatalog';
import { useIsSameSearch } from '../search/useIsSameSearch';
import { useSearchParams } from '../search/useSearchParams';
import { useGuessSupplierAccount } from './useGuessSupplierAccount';
import { useSupplierAccounts } from './useSupplierAccounts';

export const useActiveSupplierAccount = () => {
  const navigate = useAppNavigate();

  const { isTiresSearch, isDealersSearch, isCatalogSelected, selectedSupplierId, searchParams } = useSearchParams();
  const { selectedSupplierAccountId, setSelectedSupplierAccountId } = useSupplierSelector();
  const hasCatalog = useHasCatalog();
  const isSameSearch = useIsSameSearch();
  const { isMobile } = useMedia();
  const { getSearchUrl } = useGetSearchUrl();
  const { addSnackbar } = useSnackbar();

  const { accounts } = useSupplierAccounts({
    type: isTiresSearch ? 'tires' : 'parts',
  });

  const activeAccount = useMemo(
    () => accounts.find((account) => account.id === selectedSupplierAccountId) ?? null,
    [accounts, selectedSupplierAccountId]
  );

  const isSelectedAccountExist = Boolean(
    (selectedSupplierAccountId && activeAccount) || (isCatalogSelected && hasCatalog)
  );

  const needToGuessSupplier = useMemo(() => {
    if (accounts.length === 0 && !selectedSupplierAccountId) {
      return false;
    }

    return Boolean(!selectedSupplierAccountId || (selectedSupplierAccountId && !isSelectedAccountExist));
  }, [accounts.length, isSelectedAccountExist, selectedSupplierAccountId]);

  const guessedAccount = useGuessSupplierAccount({
    type: isTiresSearch ? 'tires' : 'parts',
    supplierType: isDealersSearch ? 'dealers' : 'aftermarket',
  });

  const selectSupplier = useCallback(
    (id: string, click?: boolean) => {
      if (id === selectedSupplierAccountId || !isSameSearch) {
        return;
      }

      setSelectedSupplierAccountId(id);

      if (!isMobile) {
        window.scrollTo(0, 0);
      }

      if (click) {
        if (searchParams.storeAvailability) {
          addSnackbar({
            variant: 'warning',
            header: 'We removed the selected filter',
            label: `'${searchParams.storeAvailability.at(-1)}' filter was hiding a result, so we removed it to show everything.`,
          });
        }

        navigate(
          getSearchUrl({
            ...searchParams,
            selected_distributor: id,
            storeAvailability: undefined,
            availability: searchParams.availability ?? DEFAULT_AVAILABILITY,
            page: undefined,
          }),
          { replace: true }
        );
      }
    },
    [
      selectedSupplierAccountId,
      isSameSearch,
      setSelectedSupplierAccountId,
      isMobile,
      searchParams,
      navigate,
      getSearchUrl,
      addSnackbar,
    ]
  );

  useEffect(() => {
    if (!needToGuessSupplier) {
      return;
    }

    setSelectedSupplierAccountId(guessedAccount?.id ?? null);
  }, [guessedAccount?.id, needToGuessSupplier, setSelectedSupplierAccountId]);

  useEffect(() => {
    if (!isSameSearch && !selectedSupplierId) {
      setSelectedSupplierAccountId(null);
    }
  }, [isSameSearch, selectedSupplierId, setSelectedSupplierAccountId]);

  return { activeAccount, selectedId: selectedSupplierAccountId, selectSupplier };
};
