import { Card, Typography } from '@partstech/ui';

type Props = {
  message: string;
};

export const ErrorStatus = ({ message }: Props) => (
  <Card color="negative" borderWidth={1} borderColor="mono6" px={2} radius={4} width="fit-content">
    <Typography variant="overline" textTransform="uppercase" color="negative">
      {message}
    </Typography>
  </Card>
);
