import { css } from '@emotion/react';
import { Box, Card, ImageFallback, PartstechLogo, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import view360Icon from 'images/icons/view-360-icon.svg';
import { useElementsNavigation } from 'shared/lib/react-dom';
import { ImageAction } from './ImageAction';
import type { CardProps } from '@partstech/ui';
import type { ProductImage } from 'shared/api';

const styles = {
  wrap: (hasImageViewer = false) => css`
    ${hasImageViewer && 'cursor: pointer;'}

    position: relative;
  `,
  image: css`
    max-width: 100%;
    max-height: 100%;
  `,
};

type Props = {
  adjustableNoImageSize?: boolean;
  alt: string;
  images: ProductImage[];
  onImageClick?: (index: number) => void;
  show360ViewIcon?: boolean;
  showNavigation: boolean;
} & CardProps;

export const Images = ({
  adjustableNoImageSize,
  alt,
  images,
  onImageClick,
  show360ViewIcon,
  showNavigation,
  ...cardProps
}: Props) => {
  const { current, isVisible, next, prev, show, hide } = useElementsNavigation(images.length);

  const currentImage = images[current];
  const previewImage = currentImage?.medium || currentImage?.full;

  const handleNextClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      next();
    },
    [next]
  );

  const handlePrevClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      prev();
    },
    [prev]
  );

  const handleImageClick = useCallback(() => {
    if (!currentImage) {
      return;
    }

    onImageClick?.(current);
  }, [current, currentImage, onImageClick]);

  return (
    <Card
      onMouseEnter={show}
      onMouseLeave={hide}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      background={previewImage ? 'inherit' : 'mono2'}
      borderColor="mono6"
      height={31}
      maxHeight={100}
      p={2}
      onClick={handleImageClick}
      css={styles.wrap(Boolean(onImageClick && currentImage))}
      {...cardProps}
    >
      {previewImage ? (
        <>
          {show360ViewIcon && (
            <Box position="absolute" right={2} top={1}>
              <img src={view360Icon} alt="view_360_icon" />
            </Box>
          )}

          <ImageFallback
            src={previewImage}
            alt={`${alt} #${current + 1}`}
            css={styles.image}
            data-testid="productImage"
          />

          {isVisible && showNavigation && images.length > 1 && (
            <>
              <ImageAction iconName="chevron_left" position="absolute" left={1} radius={12} onClick={handleNextClick} />
              <ImageAction
                iconName="chevron_right"
                position="absolute"
                right={1}
                radius={12}
                onClick={handlePrevClick}
              />
            </>
          )}
        </>
      ) : (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <PartstechLogo
            variant="emblem"
            color="mono17"
            size={adjustableNoImageSize ? 'small' : 'regular'}
            disableShrinkOnMobile
          />
          <Box mt={adjustableNoImageSize ? 0 : 2}>
            <Typography variant="caption" color="mono40">
              Image coming soon
            </Typography>
          </Box>
        </Box>
      )}
    </Card>
  );
};
