import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubscriptionPlanPeriod } from 'shared/api';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { getPeriodByCondition, isYearlyPeriod } from 'utils';
import { ProductAlias } from '../constants/products';
import { isStatusExpires } from '../utils/isStatusExpires';
import { useAddOns } from './useAddOns';
import { usePlans } from './usePlans';
import { useSubscription } from './useSubscription';
import { useSubscriptionSearchQuery } from './useSubscriptionSearchQuery';
import type { AddOn, Supplier } from '../types';

export const useSubscriptionSelection = () => {
  const { subscription, isRestrictedEX } = useSubscription();

  const {
    addOnsAlias,
    isUpdatePeriod = false,
    period = subscription.period || SubscriptionPlanPeriod.Year,
    planAlias,
    reset: resetSearchQuery,
    update: updateSearchQuery,
  } = useSubscriptionSearchQuery();

  const isYearly = isUpdatePeriod || isYearlyPeriod(period);

  const { isLoading: isAddOnsLoading, tireAddOn, getAddOnByAlias } = useAddOns(isYearly);
  const { isLoading: isPlansLoading, getPlanByAlias } = usePlans(isYearly);
  const {
    filterAccountsByType,
    isFetching: isFetchingAccounts,
    refetch: refetchSupplierAccounts,
  } = useGetSupplierAccounts();

  const [isOrderSuccess, setOrderSuccess] = useState(false);

  const selectedAddOns = useMemo(
    () => addOnsAlias.map(getAddOnByAlias).filter((addOn): addOn is AddOn => addOn !== undefined),
    [addOnsAlias, getAddOnByAlias]
  );

  const accountIds: string[] = useMemo(() => {
    const tireAccounts = filterAccountsByType('tires');

    if (!tireAccounts.length) {
      return [];
    }

    if (isUpdatePeriod) {
      return subscription?.tires.reduce<string[]>(
        (acc, tire) => (isStatusExpires(tire.status) ? acc : [...acc, tire.accountId]),
        []
      );
    }

    return tireAccounts.reduce<string[]>((acc, account) => (account.isPaymentPending ? [...acc, account.id] : acc), []);
  }, [filterAccountsByType, isUpdatePeriod, subscription?.tires]);

  const selectedSuppliers: Supplier[] = useMemo(() => {
    if (!isRestrictedEX) {
      return [];
    }

    return accountIds.map((accountId) => ({ accountId, id: tireAddOn?.id ?? '', price: tireAddOn?.price ?? 0 }));
  }, [accountIds, isRestrictedEX, tireAddOn?.id, tireAddOn?.price]);

  const selectAddOn = useCallback(
    (alias: ProductAlias) => {
      if (addOnsAlias.includes(alias)) {
        updateSearchQuery({ addOns: addOnsAlias.filter((addOnAlias) => addOnAlias !== alias) });
        return;
      }

      updateSearchQuery({ addOns: [...addOnsAlias, alias] });
    },
    [addOnsAlias, updateSearchQuery]
  );

  const selectPlan = useCallback(
    (alias: ProductAlias, isForceUpdate?: boolean) => {
      if (!isForceUpdate && [subscription?.plan?.alias, planAlias, ProductAlias.Free].includes(alias)) {
        updateSearchQuery({ plan: undefined });
        return;
      }

      if (!isUpdatePeriod) {
        updateSearchQuery({ plan: alias });
      }
    },
    [isUpdatePeriod, planAlias, subscription?.plan?.alias, updateSearchQuery]
  );

  const periodToggle = useCallback(() => {
    if (isUpdatePeriod || subscription?.period) {
      return;
    }

    updateSearchQuery({ period: getPeriodByCondition(!isYearlyPeriod(period)) });
  }, [isUpdatePeriod, period, subscription?.period, updateSearchQuery]);

  const updatePeriod = useCallback(() => {
    if (isRestrictedEX) {
      updateSearchQuery({
        addOns: [ProductAlias.Tires],
        isUpdatePeriod: true,
      });
      return;
    }

    updateSearchQuery({
      addOns: subscription?.addOns?.map((addOn) => addOn.alias),
      plan: subscription?.plan?.alias,
      isUpdatePeriod: true,
    });
  }, [isRestrictedEX, subscription?.addOns, subscription?.plan?.alias, updateSearchQuery]);

  const finishOrder = useCallback(() => {
    resetSearchQuery();
    setOrderSuccess(true);

    if (selectedSuppliers.length > 0) {
      refetchSupplierAccounts();
    }
  }, [resetSearchQuery, selectedSuppliers.length, refetchSupplierAccounts]);

  useEffect(() => {
    if (!selectedSuppliers.length && addOnsAlias.includes(ProductAlias.Tires)) {
      resetSearchQuery();
    }
  }, [addOnsAlias, filterAccountsByType, resetSearchQuery, selectedSuppliers, updateSearchQuery]);

  return {
    isLoading: isAddOnsLoading || isPlansLoading || isFetchingAccounts,
    isOrderSuccess,
    isUpdatePeriod,
    isYearly,
    selectedAddOns,
    selectedPlan: getPlanByAlias(planAlias),
    selectedSuppliers,
    finishOrder,
    periodToggle,
    resetSearchQuery,
    selectAddOn,
    selectPlan,
    updatePeriod,
  };
};
