import { Box, Button, CollapsibleBlock, Icon, Link, TextField, useMedia } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { Options as DefaultOptions } from './Options';
import type { FilterProps } from './types';
import type { FilterOption } from 'types/search';

const getCheckedOptions = (checkedFilterOptions: string[], filterOptions: FilterOption[]): string[] => {
  const selectedRadioOption =
    filterOptions.find((option) => option.type === 'radio' && checkedFilterOptions.includes(option.value))?.value ??
    filterOptions.find((option) => option.type === 'radio')?.value;

  const selectedCheckboxOptions = checkedFilterOptions.filter((value) =>
    filterOptions.some((option) => (!option.type || option.type === 'checkbox') && option.value === value)
  );

  return selectedRadioOption ? [selectedRadioOption, ...selectedCheckboxOptions] : selectedCheckboxOptions;
};

const VISIBLE_LIMIT = 5;
const SEARCHABLE_LIMIT = 15;

const emptyCheckedOptions: string[] = [];

export const DefaultFilter: React.FunctionComponent<FilterProps> = ({
  isCollapsed,
  isHidden = false,
  name,
  label,
  options: initialOptions,
  OptionsComponent: Options = DefaultOptions,
  onChange,
}) => {
  const { isMobile } = useMedia();

  const { checkedFilters } = useSearchResults();

  const checkedOptions = useMemo(() => checkedFilters[name] ?? emptyCheckedOptions, [checkedFilters, name]);

  const [filterValue, setFilterValue] = useState('');

  const { isToggle: isOpen, setToggle: setIsOpen } = useToggle();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterValue(e.target.value);
      setIsOpen(checkedOptions.length > 0 || e.target.value.length > 0);
    },
    [checkedOptions.length, setIsOpen]
  );

  const handleSeeAll = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      setIsOpen(true);
    },
    [setIsOpen]
  );

  const handleClear = useCallback(() => {
    setFilterValue('');
    setIsOpen(checkedOptions.length > 0);
  }, [checkedOptions.length, setIsOpen]);

  useEffect(() => {
    if (!isMobile) {
      setFilterValue('');
    }
  }, [isMobile]);

  useEffect(() => {
    if (checkedOptions.length > 0) {
      setIsOpen(true);
    }
  }, [checkedOptions.length, setIsOpen]);

  const options = useMemo(
    () =>
      isOpen
        ? initialOptions.filter((option) => String(option.name).toLowerCase().includes(filterValue.toLowerCase()))
        : initialOptions.slice(0, VISIBLE_LIMIT),
    [initialOptions, isOpen, filterValue]
  );

  const filteredCheckedOptions = useMemo(() => getCheckedOptions(checkedOptions, options), [checkedOptions, options]);

  const needsSearchInput = initialOptions.length > SEARCHABLE_LIMIT;
  const needsToCut = initialOptions.length > VISIBLE_LIMIT;

  return (
    <div style={{ display: isHidden ? 'none' : 'block' }}>
      <CollapsibleBlock
        collapsed={isCollapsed}
        title={label}
        data-testid={`${name}Filter`}
        titleColor="subtleText"
        size="dense"
        mb={4}
        contentBoxProps={{ pt: 1 }}
      >
        {needsSearchInput && (
          <Box position="relative">
            <TextField
              maxLength={100}
              placeholder="Search"
              value={filterValue}
              onChange={handleChange}
              adornmentAfter={
                filterValue ? (
                  <Button onClick={handleClear} trailingIcon="close" iconColor="mono40" variant="light" />
                ) : undefined
              }
            />
          </Box>
        )}

        <div data-testid="options">
          <Options name={name} options={options} onChange={onChange} checkedOptions={filteredCheckedOptions} />
        </div>

        {!filterValue && needsToCut && checkedOptions.length === 0 && !isOpen && (
          <Box display="flex" alignItems="center">
            <Icon name="add" color="primary" size="large" />
            <Link to="#" onClick={handleSeeAll} color="primary" data-testid="seeAllFilters">
              See all
            </Link>
          </Box>
        )}
      </CollapsibleBlock>
    </div>
  );
};
