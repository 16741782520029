import { useMutationCallback } from 'shared/api';
import { api as currentUserApi } from 'store/queries/currentUser/useGetCurrentUser';
import { api as membersApi } from 'store/queries/currentUser/useGetMembers';
import { api as generatedApi } from './RemoveUserAvatar.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    RemoveUserAvatar: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          await dispatch(
            currentUserApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
              if (!draft.currentUser) {
                return;
              }

              Object.assign(draft.currentUser, { avatar: null });
            })
          );

          await dispatch(
            membersApi.util.updateQueryData('GetMembers', undefined, (draft) => {
              if (!draft.currentUser?.activeMember?.shop?.members) {
                return;
              }

              const activeMember = draft.currentUser?.activeMember?.shop?.members.find((member) => member.active);

              if (!activeMember?.user) {
                return;
              }

              Object.assign(activeMember.user, { avatar: null });
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

const { useRemoveUserAvatarMutation } = api;

export const useRemoveUserAvatar = () => useMutationCallback(useRemoveUserAvatarMutation);
