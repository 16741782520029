import { Checkbox, RadioButton, Tooltip } from '@partstech/ui';
import React from 'react';
import type { FilterOption, FilterOptionType } from 'types/search';

type Props = {
  disabled?: boolean;
  label?: string;
  name: string;
  option: FilterOption;
  checked: boolean;
  adornmentAfter?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: FilterOptionType;
  showTooltip?: boolean;
};

export const Option = ({
  disabled,
  label,
  name,
  option,
  checked,
  adornmentAfter,
  onChange,
  type = 'checkbox',
  showTooltip,
}: Props) => {
  const Component = type === 'radio' ? RadioButton : Checkbox;

  return (
    <Tooltip
      tooltip={label}
      isHidden={!showTooltip}
      fullWidth
      verticalAlign="start"
      positioning="vertical"
      offsetY={-77}
      offsetX={26}
    >
      <Component
        data-testid={type === 'radio' ? 'radio-button' : 'checkbox'}
        checked={checked}
        id={String(option.value)}
        key={option.value}
        name={name}
        title={option.name}
        onChange={onChange}
        value={option.value}
        disabled={disabled}
      >
        {option.name} {typeof option.count === 'number' && `(${option.count})`}
        {adornmentAfter}
      </Component>
    </Tooltip>
  );
};
