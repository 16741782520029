import { shopApi } from 'shared/api';

const injectedRtkApi = shopApi.injectEndpoints({
  endpoints: (build) => ({
    Logout: build.mutation<void, void>({
      query: (body) => ({ url: '/logout', method: 'POST', body }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLogoutMutation } = injectedRtkApi;
