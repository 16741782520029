import type { PercentageAmount } from 'shared/api';

export const getPercentageAmount = (
  percentageAmount?: (Omit<PercentageAmount, 'perQuantity'> & { isPerQuantity?: boolean }) | null
) =>
  !percentageAmount
    ? null
    : {
        isPerQuantity: percentageAmount.isPerQuantity ?? false,
        max: percentageAmount.max || null,
        min: percentageAmount.min || null,
      };
