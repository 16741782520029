import { entries, isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { createBaseProductFromQuery } from 'factories';
import { useToggleRetailPrice } from 'hooks/retailPricing';
import { useSearchPreferredBrands } from 'hooks/search';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'hooks/search/useSearchParams';
// eslint-disable-next-line no-restricted-imports
import { useSearchEntryRequests } from 'hooks/searchResults/useSearchEntryRequests';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useAppSelector } from 'store';
import { isPreferredBrand, sortByHash, sortBySponsored } from 'utils';
import { selectCatalogSearchResults } from './useGetLazyCatalogProducts';
import { selectSearchResults } from './useGetLazyProducts';
import type { BaseProductFragment } from './BaseProductFragment.generated';
import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { Product, SupplierAccount, Vehicle } from 'models';
import type { PreferredBrandUnified } from 'types/preferredBrand';

const createProducts = (
  accounts: SupplierAccount[],
  preferredBrands: PreferredBrandUnified[],
  products: BaseProductFragment[],
  supplierAccountId: string,
  vehicle: Vehicle | null,
  showRetailPrice: boolean,
  filterEnhancements?: boolean,
  backendSortLogic?: boolean
) => {
  const baseProducts = products
    .map((product) => {
      const account = accounts.find((item) => item.id === supplierAccountId);

      return createBaseProductFromQuery({
        account,
        product,
        vehicleId: vehicle?.id,
        isPreferred: isPreferredBrand(preferredBrands)(
          product.brand?.id ?? '',
          product.brand?.name ?? '',
          product.partType?.id ?? ''
        ),
        showRetailPrice,
        filterEnhancements,
      });
    })
    .filter(isNotNull);

  return backendSortLogic ? baseProducts : baseProducts.sort(sortByHash).sort(sortBySponsored);
};

export const useProductResults = () => {
  const { getSearchEntryRequests, getCatalogSearchEntryRequest } = useSearchEntryRequests();
  const { isTiresSearch, vehicle } = useSearchParams();
  const { preferredBrands } = useSearchPreferredBrands();

  const searchEntryRequests = useMemo(() => getSearchEntryRequests(), [getSearchEntryRequests]);
  const catalogSearchEntryRequest = useMemo(() => getCatalogSearchEntryRequest(), [getCatalogSearchEntryRequest]);
  const { accounts } = useSupplierAccounts({
    type: isTiresSearch ? 'tires' : 'parts',
  });

  const { isShown: showRetailPrice } = useToggleRetailPrice();
  const {
    flags: { availabilityFilterEnhancements, useProductSortingLogicOnTheBackend: backendSortLogic },
  } = useLaunchDarkly();

  const results = useAppSelector((state) => selectSearchResults(state, searchEntryRequests), shallowEqual);
  const catalogResults = useAppSelector(
    (state) => selectCatalogSearchResults(state, catalogSearchEntryRequest),
    shallowEqual
  );

  const productResults = useMemo(
    () => ({
      ...entries({ ...results, ...catalogResults }).reduce<
        Record<string, { status: QueryStatus; products: Product[]; errors?: string[] }>
      >(
        (acc, [supplierAccountId, result]) => ({
          ...acc,
          [supplierAccountId]: {
            ...result,
            products: createProducts(
              accounts,
              preferredBrands,
              result.products,
              supplierAccountId,
              vehicle,
              showRetailPrice,
              availabilityFilterEnhancements,
              backendSortLogic
            ),
          },
        }),
        {}
      ),
    }),
    [
      results,
      catalogResults,
      accounts,
      preferredBrands,
      vehicle,
      showRetailPrice,
      availabilityFilterEnhancements,
      backendSortLogic,
    ]
  );

  return {
    productResults,
  };
};
