import { subscriptionApi } from 'features/subscription';
import { AccountStatus, SubscriptionSubscriptionPlanStatus } from 'shared/api';
import { api as rootSupplierAccountsApi } from 'store/queries/currentUser/supplierAccounts/GetSupplierAccounts.generated';
import { updateSupplierAccountAction } from 'utils';
import { api as generatedApi } from './DeleteSupplierAccount.generated';
import type { GetSupplierAccountsQuery } from 'store/queries/currentUser/supplierAccounts/GetSupplierAccounts.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteSupplierAccount: {
      invalidatesTags: ['Subscription', 'SubscriptionUpcomingUpdatePeriodInvoice'],
      onQueryStarted: async ({ input: { accountId } }, { queryFulfilled, dispatch, getState }) => {
        const { data } = await queryFulfilled;

        const supplierAccountsQuery = getState().api.queries['GetSupplierAccounts({})']
          ?.data as GetSupplierAccountsQuery;

        const isApprovedPaidSupplier = supplierAccountsQuery.currentUser?.activeMember?.shop?.accounts?.some(
          (account) => {
            const { id, status, supplier } = account;
            return id === accountId && supplier?.paid && status === AccountStatus.Approved;
          }
        );

        if (isApprovedPaidSupplier) {
          dispatch(
            subscriptionApi.util.updateQueryData('GetSubscription', undefined, ({ currentUser }) => {
              const shop = currentUser?.activeMember?.shop;
              const shopSubscription = shop?.shopSubscription;

              const plans = shopSubscription?.plans?.map((plan) => {
                if (plan.account?.id === accountId) {
                  return { ...plan, status: SubscriptionSubscriptionPlanStatus.CancelRequested };
                }

                return plan;
              });

              if (shop) {
                Object.assign(shop, { shopSubscription: { ...shopSubscription, plans } });
              }
            })
          );

          return;
        }

        dispatch(updateSupplierAccountAction(accountId, { store: null }));

        const deleteAccountId = data.deleteShopAccount?.id;

        if (!deleteAccountId) {
          return;
        }

        dispatch(
          rootSupplierAccountsApi.util.updateQueryData('GetSupplierAccounts', {}, (draft) => {
            const shop = draft.currentUser?.activeMember?.shop;
            const accounts = shop?.accounts?.filter((account) => account.id !== deleteAccountId);

            if (accounts && shop) {
              Object.assign(shop, { accounts });
            }
          })
        );
      },
    },
  },
});

export const { useDeleteSupplierAccountMutation } = api;
