import { css } from '@emotion/react';
import { Button } from '@partstech/ui';
import draggableIcon from 'images/icons/draggable.svg';
import type { ButtonProps } from '@partstech/ui';

const styles = {
  dragButton: css`
    padding: 0;
    height: auto;
    cursor: inherit;

    span {
      display: flex;
      min-height: auto;
      min-width: auto;
    }
  `,
};

export const DragButton = (props: ButtonProps) => (
  <Button title="Drag to change supplier's priority" variant="text" noHover {...props} css={styles.dragButton}>
    <img alt="drag_indicator" src={draggableIcon} />
  </Button>
);
