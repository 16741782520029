export enum Routes {
  ADD_SUPPLIER = '/add-supplier/:tab',
  QUOTE = '/quote/:quoteId',
  CREATE_QUOTE = '/quote/create',
  CART = '/cart',
  CART_TRANSFER = '/cart/transfer',
  EDIT_LABOR = '/add-labor',
  DASHBOARD = '/',
  DIAGRAM = '/diagram/:vehicleId/:categoryId/:diagramId',
  LOGIN = '/login',
  LOGIN_BY_INVITATION = '/login-by-invitation',
  LOGIN_GOOGLE = '/google/login',
  MY_SHOP = '/my-shop/:page',
  ORDER = '/order/:userId/:hash',
  PRODUCT_PAGE = '/product/:partNumberId',
  RECENT_ORDERS = '/recent/purchases',
  REPORTS = '/analytics',
  REPORTS_SPENDING = '/analytics/spending',
  REPORTS_TIRES = '/analytics/tires',
  REPORTS_TIRE_SIZE = '/analytics/tires/tire-size-page/:tireSize',
  REPORTS_PARTS = '/analytics/parts',
  REPORTS_PART_TYPE = '/analytics/parts/part-type-page/:partType',
  RETAIL_PRICING_CALCULATOR_FRAME = '/retail-pricing-calculator/frame',
  RESET_PASSWORD_REQUEST = '/resetting/request',
  RESET_PASSWORD_FORM = '/resetting/reset/:hash',
  SAVED_QUOTES = '/saved-quotes/:tab',
  SAVED_QUOTE = '/saved-quotes/:tab/:id',
  SEARCH_RESULTS = '/searchresult',
  SIGN_UP_GOOGLE = '/google/sign-up',
  SIGN_UP_INVITATION = '/sign-up/invitation',
  SIGN_UP = '/sign-up/:page',
  SMS_CONNECTIONS = '/sms-connections/:hash?/:page?',
  SHOP = '/shop/:id',
  STOCK_ORDER = '/stock-order',
  STOCK_ORDER_NEW = '/stock-order/new',
  STOCK_ORDER_TEMPLATES = '/stock-order/templates',
  STOCK_ORDER_TEMPLATE = '/stock-order/templates/:templateId',
  STOCK_ORDER_RESULTS = '/stock-order/search-results/:stockOrderId/:rowIndex',
  SUPPLIERS_FRAME = '/distributor-preferences/frame',
  SUPPLIER_PROFILE = '/distributor/:slug',
  SUBSCRIPTION = '/my-shop/subscription/:page',
  MITCHELL1_SETUP = '/mitchell1/setup',
  SERVICE_GUIDES = '/service-guides/:vehicleId/:tab',
}

export const ModalRoutes = [
  Routes.ADD_SUPPLIER,
  Routes.EDIT_LABOR,
  Routes.DIAGRAM,
  Routes.STOCK_ORDER_RESULTS,
  Routes.SERVICE_GUIDES,
  Routes.SAVED_QUOTE,
];
