import type { AttributeInput, CatalogSearchInput } from 'shared/api';

export type ToCatalogRequestParams = {
  attributes: AttributeInput[] | undefined;
  vehicleId: string | undefined;
  vin: string | undefined;
};

export interface SearchCatalogRequestConvertable {
  toCatalogRequest(params: ToCatalogRequestParams): CatalogSearchInput;
}

export const isSearchCatalogRequestConvertable = (model: object): model is SearchCatalogRequestConvertable =>
  'toCatalogRequest' in model;
