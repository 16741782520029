export const INITIALIZE_KEY = '53nu41g1';

export enum SupportKey {
  Default = 'Sr3G26T',
}

/** @knipignore */
export enum ConnectSmsKey {
  AutoFluent = 'zZikZevd6i',
  AutoLeap = 'qthNh4aJaw',
  Mitchell1 = '_K7cc3q3l1',
  NapaTRACS = 'DIpBhDNrMR',
  Other = 'QQ1ma3nwt4',
  Protractor = 'dyYJ435zjm',
  ShopBoss = 'DWUUoEKqNT',
  ShopMonkey = 'zadU5HAwlg',
  ShopWare = 'A-mJCDjHL5',
  Tekmetric = 'pjC1zf7Hwv',
  TireGuru = 'wciu8-yn1-',
}
