/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { MemberPermissionsFragmentDoc } from './MemberPermissions.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetMembersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMembersQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        invitations?: Array<{
          __typename?: 'Invitation';
          id: string;
          email: Types.Scalars['String']['input'];
          sentDate: Types.Scalars['DateTime']['input'];
          status: Types.InvitationStatus;
          permissions?: {
            __typename?: 'MemberPermissions';
            allOrders: Types.Scalars['Boolean']['input'];
            boschRewards: Types.Scalars['Boolean']['input'];
            brandPreferences: Types.Scalars['Boolean']['input'];
            partsReport: Types.Scalars['Boolean']['input'];
            payments: Types.Scalars['Boolean']['input'];
            reportsDashboardAccess: Types.Scalars['Boolean']['input'];
            retailPricing: Types.Scalars['Boolean']['input'];
            shipping: Types.Scalars['Boolean']['input'];
            shopProfile: Types.Scalars['Boolean']['input'];
            spendReport: Types.Scalars['Boolean']['input'];
            subscription: Types.Scalars['Boolean']['input'];
            suppliers: Types.Scalars['Boolean']['input'];
            tireReport: Types.Scalars['Boolean']['input'];
            viewWholesaleCosts: Types.Scalars['Boolean']['input'];
          } | null;
        }> | null;
        members?: Array<{
          __typename?: 'ShopMember';
          id: string;
          active: Types.Scalars['Boolean']['input'];
          isPrimary: Types.Scalars['Boolean']['input'];
          permissions?: {
            __typename?: 'MemberPermissions';
            allOrders: Types.Scalars['Boolean']['input'];
            boschRewards: Types.Scalars['Boolean']['input'];
            brandPreferences: Types.Scalars['Boolean']['input'];
            partsReport: Types.Scalars['Boolean']['input'];
            payments: Types.Scalars['Boolean']['input'];
            reportsDashboardAccess: Types.Scalars['Boolean']['input'];
            retailPricing: Types.Scalars['Boolean']['input'];
            shipping: Types.Scalars['Boolean']['input'];
            shopProfile: Types.Scalars['Boolean']['input'];
            spendReport: Types.Scalars['Boolean']['input'];
            subscription: Types.Scalars['Boolean']['input'];
            suppliers: Types.Scalars['Boolean']['input'];
            tireReport: Types.Scalars['Boolean']['input'];
            viewWholesaleCosts: Types.Scalars['Boolean']['input'];
          } | null;
          user?: {
            __typename?: 'User';
            id: string;
            avatar?: Types.Scalars['String']['input'] | null;
            firstName: Types.Scalars['String']['input'];
            lastName: Types.Scalars['String']['input'];
            email: Types.Scalars['String']['input'];
            phone?: Types.Scalars['String']['input'] | null;
          } | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetMembersDocument = `
    query GetMembers {
  currentUser {
    activeMember {
      shop {
        invitations {
          id
          email
          sentDate
          status
          permissions {
            ...MemberPermissions
          }
        }
        members {
          id
          active
          isPrimary
          permissions {
            ...MemberPermissions
          }
          user {
            id
            avatar
            firstName
            lastName
            email
            phone
          }
        }
      }
    }
  }
}
    ${MemberPermissionsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetMembers: build.query<GetMembersQuery, GetMembersQueryVariables | void>({
      query: (variables) => ({ document: GetMembersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetMembersQuery, useLazyGetMembersQuery } = injectedRtkApi;
