import { api as generatedApi } from './GetSubscriptionUpcomingUpdatePeriodInvoice.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSubscriptionUpcomingUpdatePeriodInvoice: {
      providesTags: ['SubscriptionUpcomingUpdatePeriodInvoice'],
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    },
  },
});

const { useGetSubscriptionUpcomingUpdatePeriodInvoiceQuery } = api;

type Args = {
  couponId?: string;
  skip?: boolean;
};

export const useGetSubscriptionUpcomingUpdatePeriodInvoice = ({ couponId, skip }: Args) => {
  const { data, ...rest } = useGetSubscriptionUpcomingUpdatePeriodInvoiceQuery({ input: { couponId } }, { skip });

  const upcomingUpdatePeriodInvoice = data?.currentUser?.activeMember?.shop?.subscriptionUpcomingUpdatePeriodInvoice;

  return {
    ...rest,
    upcomingUpdatePeriodInvoice,
  };
};
