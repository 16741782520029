/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CartLaborItemFragmentDoc } from '../../../../../shared/api/graphql/fragment/CartLaborItem.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type AddLaborItemsToCartMutationVariables = Types.Exact<{
  items: Array<Types.AddLaborItemToActiveCartInput> | Types.AddLaborItemToActiveCartInput;
}>;

export type AddLaborItemsToCartMutation = {
  __typename?: 'Mutation';
  batchAddLaborItemsToActiveCart?: {
    __typename?: 'BatchAddLaborItemsToActiveCartPayload';
    error?: {
      __typename?: 'AddLaborItemToActiveCartErrorPayload';
      errorMessage: Types.Scalars['String']['input'];
    } | null;
    items?: Array<{
      __typename?: 'CartLaborItem';
      id: string;
      hours: Types.Scalars['Float']['input'];
      defaultHours: Types.Scalars['Float']['input'];
      vin?: Types.Scalars['String']['input'] | null;
      orderItemId?: string | null;
      name: Types.Scalars['String']['input'];
      notes?: Types.Scalars['String']['input'] | null;
      skill: Types.Scalars['String']['input'];
      identifiers?: { __typename?: 'Mitchell1Identifiers' } | { __typename?: 'MotorIdentifiers'; id: string } | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
      taxonomy?: {
        __typename?: 'LaborTaxonomy';
        action?: Types.Scalars['String']['input'] | null;
        id: string;
        name: Types.Scalars['String']['input'];
      } | null;
    }> | null;
  } | null;
};

export const AddLaborItemsToCartDocument = `
    mutation AddLaborItemsToCart($items: [AddLaborItemToActiveCartInput!]!) {
  batchAddLaborItemsToActiveCart(input: {items: $items}) {
    error {
      errorMessage
    }
    items {
      ...CartLaborItem
    }
  }
}
    ${CartLaborItemFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddLaborItemsToCart: build.mutation<AddLaborItemsToCartMutation, AddLaborItemsToCartMutationVariables>({
      query: (variables) => ({ document: AddLaborItemsToCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddLaborItemsToCartMutation } = injectedRtkApi;
