type UserData = {
  avatar?: string | null;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone?: string | null;
};

export class User {
  avatar: string | null = null;

  email: string;

  firstName: string;

  id: string;

  lastName: string;

  phone: string | null = null;

  constructor(user: UserData) {
    this.avatar = user.avatar ?? null;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.id = user.id;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getInitials() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
  }
}
