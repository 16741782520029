import { sortBy } from '@partstech/ui/utils';
import { targetSupplierIds } from 'constant';
import { SupplierId } from 'types/supplier';
import type { FilterOption } from 'types/search';

export const sortAvailabilityOptions = (options: FilterOption[], supplierId: string): FilterOption[] => {
  const isATD = [SupplierId.ATD, SupplierId.ATD_TIRECONNECT].includes(Number(supplierId));

  if (isATD) {
    return sortBy(options, 'name');
  }

  const isTireHub = [SupplierId.TIRE_HUB, SupplierId.TIRE_HUB_TIRECONNECT].includes(Number(supplierId));
  if (isTireHub) {
    const sortOrder: string[] = ['today', 'tomorrow', 'within 2 days', 'within 3 days', 'more than 3 days'];

    return options
      .slice()
      .sort((a, b) => sortOrder.indexOf(a.value.toLowerCase()) - sortOrder.indexOf(b.value.toLowerCase()));
  }

  if (targetSupplierIds.includes(Number(supplierId))) {
    const sortOrder: string[] = [
      'home store',
      'pick up',
      'within 2 hours',
      'within 4 hours',
      'same day',
      'next day',
      '2+ days',
      'call for eta',
    ];

    return options
      .slice()
      .sort((a, b) => sortOrder.indexOf(a.value.toLowerCase()) - sortOrder.indexOf(b.value.toLowerCase()));
  }

  const defaultSortOrder: string[] = ['fastest delivery', 'direct delivery', 'in network'];

  return options
    .slice()
    .sort((a, b) => defaultSortOrder.indexOf(a.value.toLowerCase()) - defaultSortOrder.indexOf(b.value.toLowerCase()));
};
