import { entries, isEmpty, keys, uniq } from '@partstech/ui/utils';
import { type CreateRecentSearchInput, RecentSearchType, type RecentSearchAttributeInput } from 'shared/api';
import { isMatchingString } from 'shared/lib/string';
import { PartType } from './PartType';
import type { Vehicle } from './Vehicle';
import type { SearchQuery } from 'features/searchForm';

type SelectedAttribute = Record<string, string[]>;

export class UniversalPartType extends PartType {
  selectedAttributes: SelectedAttribute = {};

  hasAttributes() {
    return (this.attributes ?? []).length > 0;
  }

  isAllAttributesFilled() {
    return (this.attributes ?? []).length === keys(this.selectedAttributes).length;
  }

  isMultiAttribute() {
    return (this.attributes ?? []).length > 1;
  }

  isSingleAttribute() {
    return this.attributes?.length === 1;
  }

  setSelectedAttributes(attributes: SelectedAttribute) {
    this.selectedAttributes = attributes;

    return this;
  }

  shouldPickAttributes(hasVehicle: boolean): boolean {
    if (this.isMultiAttribute() && !this.isAllAttributesFilled()) {
      return true;
    }

    if (hasVehicle) {
      return false;
    }

    return !this.isAllAttributesFilled();
  }

  getSelectedAttributes() {
    return entries(this.selectedAttributes).reduce<SelectedAttribute>(
      (acc, [name, values]) => ({
        ...acc,
        [name]: uniq(values),
      }),
      {}
    );
  }

  resetSelectedAttributes() {
    this.selectedAttributes = {};

    return this;
  }

  getFirstAttribute() {
    return this.attributes?.[0] ?? null;
  }

  hasSelectedAttributeWithValue(value: string) {
    const selectedAttributes = this.getSelectedAttributes();

    if (isEmpty(selectedAttributes)) {
      return false;
    }

    return Object.values(selectedAttributes)?.some((values) =>
      values?.some((attributeValue) => isMatchingString(attributeValue, value))
    );
  }

  findAttributeByValue(value: string) {
    return this.attributes?.find((attribute) =>
      attribute.values?.find((attributeValue) => isMatchingString(attributeValue, value))
    );
  }

  toQuery(vehicle?: Vehicle | null): SearchQuery {
    return {
      part_text_id: this.id,
      part_text: this.name,
      attributes: this.getSelectedAttributes(),
      prefill: isEmpty(this.selectedAttributes) && vehicle ? 'required' : undefined,
    };
  }

  /**
   * Returns the names of selected attributes joined by a comma
   */
  toString() {
    if (isEmpty(this.selectedAttributes)) {
      return this.name;
    }

    return Object.values(this.selectedAttributes).join(', ');
  }

  toRecentSearch(vehicle: Vehicle | null): CreateRecentSearchInput {
    return {
      type: RecentSearchType.NonApp,
      partText: {
        id: this.id,
        text: this.name,
      },
      attributes: entries(this.getSelectedAttributes()).flatMap<RecentSearchAttributeInput>(([name, values]) => ({
        name,
        values,
      })),
      vehicleID: vehicle?.id,
      state: vehicle?.state,
      plate: vehicle?.plate,
      vin: vehicle?.vin,
    };
  }
}
