import type { MemberPermissions } from './MemberPermissions';
import type { User } from './User';
import type { Shop } from 'store/queries/currentUser/useGetCurrentUser';

type MemberData = {
  active: boolean;
  apiKey?: string;
  id: string;
  isPrimary: boolean;
  permissions: MemberPermissions;
  shop?: Shop | null;
  user: User;
};

export class Member {
  apiKey: string | null = null;

  id: string;

  isActive: boolean = true;

  isPrimary: boolean;

  permissions: MemberPermissions;

  user: User;

  shop: Shop | null = null;

  constructor(member: MemberData) {
    this.isActive = member.active ?? true;
    this.apiKey = member.apiKey ?? null;
    this.id = member.id;
    this.isPrimary = member.isPrimary;
    this.permissions = member.permissions;
    this.shop = member.shop ?? null;
    this.user = member.user;
  }
}
