import { api, gExportApi, gUploadApi, shopApi } from 'shared/api';
import { api as logoutApi } from 'store/mutations/logout';
import type { Action, MiddlewareAPI, ThunkDispatch } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export const resetMiddleware =
  <D extends ThunkDispatch<RootState, unknown, Action>, S extends RootState>({ dispatch }: MiddlewareAPI<D, S>) =>
  (next: (action: unknown) => unknown) =>
  (action: unknown) => {
    if (logoutApi.endpoints.Logout.matchFulfilled(action)) {
      dispatch(api.util.resetApiState());
      dispatch(shopApi.util.resetApiState());
      dispatch(gUploadApi.util.resetApiState());
      dispatch(gExportApi.util.resetApiState());

      sessionStorage.clear();
      localStorage.clear();
    }

    return next(action);
  };
