import type { Filter, FilterOptionType } from 'types/search';

type Props = {
  checked: boolean;
  type?: FilterOptionType;
  checkedValues: string[];
  changedValues: string[];
  changedFilter?: Filter;
};

export const getUpdatedCheckedValues = ({
  checked,
  type,
  checkedValues,
  changedValues,
  changedFilter,
}: Props): string[] => {
  if (type === 'radio') {
    const nonRadioValues = checkedValues.filter((checkedValue) =>
      changedFilter?.options.some((option) => option.value === checkedValue && option.type !== 'radio')
    );

    return checked ? [...nonRadioValues, ...changedValues] : nonRadioValues;
  }

  return checked ? [...checkedValues, ...changedValues] : checkedValues.filter((val) => !changedValues.includes(val));
};
