/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { UpcomingInvoiceFragmentDoc } from '../upcomingInvoice/UpcomingInvoice.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetSubscriptionUpcomingUpdatePeriodInvoiceQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UpcomingInvoicePeriodInput>;
}>;

export type GetSubscriptionUpcomingUpdatePeriodInvoiceQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        subscriptionUpcomingUpdatePeriodInvoice?: {
          __typename?: 'SubscriptionUpcomingInvoice';
          grandTotal: Types.Scalars['BigDecimal']['input'];
          nextPaymentAmount: Types.Scalars['BigDecimal']['input'];
          tax: Types.Scalars['BigDecimal']['input'];
          total: Types.Scalars['BigDecimal']['input'];
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetSubscriptionUpcomingUpdatePeriodInvoiceDocument = `
    query GetSubscriptionUpcomingUpdatePeriodInvoice($input: UpcomingInvoicePeriodInput) {
  currentUser {
    activeMember {
      shop {
        subscriptionUpcomingUpdatePeriodInvoice(input: $input) {
          ...UpcomingInvoice
        }
      }
    }
  }
}
    ${UpcomingInvoiceFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubscriptionUpcomingUpdatePeriodInvoice: build.query<
      GetSubscriptionUpcomingUpdatePeriodInvoiceQuery,
      GetSubscriptionUpcomingUpdatePeriodInvoiceQueryVariables | void
    >({
      query: (variables) => ({ document: GetSubscriptionUpcomingUpdatePeriodInvoiceDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetSubscriptionUpcomingUpdatePeriodInvoiceQuery,
  useLazyGetSubscriptionUpcomingUpdatePeriodInvoiceQuery,
} = injectedRtkApi;
