import { gUploadApi } from 'shared/api';
import { getImageBase64FromFormData } from 'shared/lib/base64';
import { api as currentUserApi } from 'store/queries/currentUser/useGetCurrentUser';
import { api as membersApi } from 'store/queries/currentUser/useGetMembers';

const api = gUploadApi.injectEndpoints({
  endpoints: (build) => ({
    UploadUserAvatar: build.mutation<{ success: boolean }, FormData>({
      query: (formData) => ({
        url: `/user-avatar`,
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),

      onQueryStarted: async (input, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          const avatar = await getImageBase64FromFormData(input);

          await dispatch(
            currentUserApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
              if (!draft.currentUser) {
                return;
              }

              Object.assign(draft.currentUser, { avatar });
            })
          );

          await dispatch(
            membersApi.util.updateQueryData('GetMembers', undefined, (draft) => {
              if (!draft.currentUser?.activeMember?.shop?.members) {
                return;
              }

              const activeMember = draft.currentUser?.activeMember?.shop?.members.find((member) => member.active);

              if (!activeMember?.user) {
                return;
              }

              Object.assign(activeMember.user, { avatar });
            })
          );
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const { useUploadUserAvatarMutation } = api;
