import { useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { BundleDealModal } from './BundleDealsModal';
import type { BundleDeal } from 'models';

export const useBundleDealModal = (bundleDeal: BundleDeal | null) => {
  const { isMobile } = useMedia();

  const { open } = useCreateModal(
    BundleDealModal,
    { size: isMobile ? 'bottomSheet' : 'small', bundleDeal },
    { customId: 'bundleDeal' }
  );

  const openBundleDealModal = useCallback(() => {
    if (!bundleDeal) {
      return;
    }

    open();
  }, [bundleDeal, open]);

  return { openBundleDealModal };
};
