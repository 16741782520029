import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { DateFormat, targetSupplierIds } from 'constant';
import { EtaBanner } from './EtaBanner';
import type { BannerProps, TooltipProps } from '@partstech/ui';

type DeliveryNote = {
  label?: string | null;
  text?: string | null;
};

type Props = {
  variant?: 'default' | 'preview';
  supplierId: number;
  deliveryNotes: DeliveryNote[];
  isInNetwork?: boolean;
  availabilityGroup?: string | null;
  orderPlacedDate?: string | null;
  tooltipProps?: Omit<TooltipProps, 'tooltip'>;
  isPaid?: boolean;
} & Omit<BannerProps, 'variant' | 'color'>;

export const OrderItemEtaMessage = ({
  variant = 'default',
  supplierId,
  deliveryNotes,
  isInNetwork = false,
  availabilityGroup,
  orderPlacedDate,
  tooltipProps,
  isPaid = false,
  ...bannerProps
}: Props) => {
  const tooltip = orderPlacedDate
    ? `Order was placed ${DateTime.fromISO(orderPlacedDate).toFormat(DateFormat.type3)}`
    : '';

  const color = useMemo(() => {
    if (isPaid) {
      return 'positive';
    }

    if (isInNetwork) {
      return 'secondary';
    }

    if (availabilityGroup === 'Within 2 hours' && targetSupplierIds.includes(supplierId)) {
      return 'mix';
    }

    return 'positive';
  }, [availabilityGroup, isInNetwork, isPaid, supplierId]);

  const hasIcon = useMemo(
    () =>
      targetSupplierIds.includes(supplierId) &&
      !isInNetwork &&
      Boolean(availabilityGroup) &&
      availabilityGroup !== 'Within 2 hours',
    [availabilityGroup, isInNetwork, supplierId]
  );

  const filteredDeliveryNotes = useMemo(
    () => (isPaid ? deliveryNotes.filter((note) => note.label?.toLowerCase() !== 'order by') : deliveryNotes),
    [deliveryNotes, isPaid]
  );

  return (
    <EtaBanner
      variant={variant}
      messages={filteredDeliveryNotes}
      hasIcon={hasIcon}
      color={color}
      data-testid="eta"
      tooltip={tooltip}
      tooltipProps={tooltipProps}
      {...bannerProps}
    />
  );
};
