import { makeContext } from '@partstech/ui/utils';
import type { Dispatch, SetStateAction } from 'react';

type SupplierSelectorContextValue = {
  isSticky: boolean;
  setIsSticky: Dispatch<SetStateAction<boolean>>;
  selectedSupplierAccountId: string | null;
  setSelectedSupplierAccountId: (supplierAccountId: string | null) => void;
};

export const [useSupplierSelector, SupplierSelectorContextProvider] = makeContext<SupplierSelectorContextValue>();
