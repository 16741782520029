import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { createMemberFromData } from 'factories/createMemberFromData';
import { createMemberInvitationFromData } from 'factories/createMemberInvitationFromData';
import { api as generatedApi } from './GetMembers.generated';
import type { GetMembersQuery } from './GetMembers.generated';
import type { Member as MemberModel, MemberInvitation } from 'models';
import type { ArrayItemType } from 'types/general';

type CurrentUser = NonNullable<GetMembersQuery['currentUser']>;
type ActiveMember = NonNullable<CurrentUser['activeMember']>;
type Shop = NonNullable<ActiveMember['shop']>;
export type Members = NonNullable<Shop['members']>;
export type Member = NonNullable<ArrayItemType<Members>>;
export type Invitations = NonNullable<Shop['invitations']>;

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Invitation', 'Member'],
  endpoints: {
    GetMembers: {
      providesTags: (response: GetMembersQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;
        if (!shop) {
          return ['Invitation', 'Member'];
        }

        const memberTags =
          shop?.members?.map((member) => ({
            type: 'Member' as const,
            id: member?.id,
          })) ?? [];

        const invitationTags =
          shop?.invitations?.map((invitation) => ({
            type: 'Invitation' as const,
            id: invitation?.id,
          })) ?? [];

        return ['Invitation', 'Member', ...memberTags, ...invitationTags];
      },
    },
  },
});

const { useGetMembersQuery } = api;

const emptyMembers: MemberModel[] = [];
const emptyInvitations: MemberInvitation[] = [];

export const useGetMembers = () => {
  const { data, ...props } = useGetMembersQuery();

  const membersData = data?.currentUser?.activeMember?.shop?.members;
  const invitationsData = data?.currentUser?.activeMember?.shop?.invitations;

  const members = useMemo(
    () => membersData?.map(createMemberFromData).filter(isNotNull) ?? emptyMembers,
    [membersData]
  );

  const invitations = useMemo(
    () => invitationsData?.map(createMemberInvitationFromData) ?? emptyInvitations,
    [invitationsData]
  );

  const getInvitationById = useCallback(
    (id?: string) => invitations.find((invitation) => invitation.id === id),
    [invitations]
  );

  const getMemberById = useCallback((id?: string) => members.find((member) => member.id === id), [members]);

  const getMembersByIds = useCallback(
    (ids: number[] | string[]) => members.filter((member) => ids.map(String).includes(member.id)),
    [members]
  );

  const count = members.length + invitations.length;

  return {
    count,
    hasMembers: count > 1,
    invitations,
    members,
    getInvitationById,
    getMembersByIds,
    getMemberById,
    ...props,
  };
};
