import type { IconName, ThemeColorName } from '@partstech/ui';

type AvailabilityLineType = 'MAIN' | 'NETWORK' | 'CLOSEST';

export type AvailabilityLineData = {
  address: string | null;
  group: string | null;
  id: number | null;
  name: string;
  quantity: number;
  type: AvailabilityLineType;
};

export class AvailabilityLine {
  address: string | null;

  group: string | null;

  id: number | null;

  name: string;

  nickname: string | null = null;

  quantity: number;

  type: AvailabilityLineType;

  constructor(line: AvailabilityLineData) {
    this.address = line.address;
    this.group = line.group;
    this.id = line.id;
    this.name = line.name;
    this.quantity = line.quantity;
    this.type = line.type;
  }

  get isMaxQuantity() {
    return this.quantity > 99;
  }

  get formattedQuantity() {
    return `${this.quantity}`;
  }

  get formattedMaxQuantity() {
    if (this.isMaxQuantity) {
      return `99+`;
    }

    return this.formattedQuantity;
  }

  isClosest() {
    return this.type === 'CLOSEST';
  }

  isNetwork() {
    return this.type === 'NETWORK';
  }

  isMain() {
    return this.type === 'MAIN';
  }

  isClosestInStock() {
    return this.isClosest() && this.quantity > 0;
  }

  isMainInStock() {
    return this.isMain() && this.quantity > 0;
  }

  isNetworkInStock() {
    return this.isNetwork() && this.quantity > 0;
  }

  get quantityColor(): ThemeColorName {
    if (this.quantity === 0) {
      return 'negative';
    }

    if (this.isNetwork()) {
      return 'neutral';
    }

    return 'positive';
  }

  get displayName(): string {
    return this.nickname ?? this.name;
  }

  isEqual(item: Pick<AvailabilityLine, 'id' | 'name'>): boolean {
    return Boolean(item && ((item.id !== null && item.id === this.id) || item.name === this.name));
  }

  getIcon(): IconName | undefined {
    return 'storefront';
  }

  getAdditionalTitle() {
    if (this.isMain()) {
      return `${'Home Store'}: ${this.displayName}`;
    }

    if (this.isNetwork() || this.isClosest()) {
      return this.displayName;
    }

    return null;
  }

  getTitle() {
    if (this.isMain()) {
      return this.getMainAvailabilityTitle();
    }

    if (this.isNetwork()) {
      return this.getNetworkAvailabilityTitle();
    }

    if (this.isClosest()) {
      return this.getClosestAvailabilityTitle();
    }

    return null;
  }

  isMatchingGroup(item: Pick<AvailabilityLine, 'group'>): boolean {
    return Boolean(item && item.group === this.group);
  }

  getClosestAvailabilityTitle(): string {
    return this.getNetworkAvailabilityTitle();
  }

  getMainAvailabilityTitle(): string {
    return `In Stock (${this.formattedMaxQuantity})`;
  }

  getNetworkAvailabilityTitle(): string {
    return `Available (${this.formattedMaxQuantity})`;
  }

  setNickname(nickname?: string | null) {
    this.nickname = nickname ?? null;
  }
}
