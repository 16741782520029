/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetSubscriptionCouponValidateQueryVariables = Types.Exact<{
  input: Types.SubscriptionCouponInput;
}>;

export type GetSubscriptionCouponValidateQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        subscriptionCouponValidate?: {
          __typename?: 'SubscriptionCoupon';
          isValid?: Types.Scalars['Boolean']['input'] | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetSubscriptionCouponValidateDocument = `
    query GetSubscriptionCouponValidate($input: SubscriptionCouponInput!) {
  currentUser {
    activeMember {
      shop {
        subscriptionCouponValidate(input: $input) {
          isValid
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubscriptionCouponValidate: build.query<
      GetSubscriptionCouponValidateQuery,
      GetSubscriptionCouponValidateQueryVariables
    >({
      query: (variables) => ({ document: GetSubscriptionCouponValidateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSubscriptionCouponValidateQuery, useLazyGetSubscriptionCouponValidateQuery } = injectedRtkApi;
