/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { AddressFragmentDoc } from '../../../../shared/api/graphql/fragment/Address.generated';
import { CoordinatesFragmentDoc } from '../../../../shared/api/graphql/fragment/Coordinates.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetAddressesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAddressesQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        accountAddresses?: Array<{
          __typename?: 'ShopAccountAddress';
          id: string;
          isDefault: Types.Scalars['Boolean']['input'];
          address: {
            __typename?: 'Address';
            address1: Types.Scalars['String']['input'];
            address2?: Types.Scalars['String']['input'] | null;
            city: Types.Scalars['String']['input'];
            country: Types.Country;
            state: Types.Scalars['String']['input'];
            zipCode: Types.Scalars['String']['input'];
          };
        }> | null;
        addresses?: Array<{
          __typename?: 'ShopAddress';
          id: string;
          isDefault: Types.Scalars['Boolean']['input'];
          timeZoneId?: Types.Scalars['String']['input'] | null;
          type: Types.ShopAddressType;
          address: {
            __typename?: 'Address';
            address1: Types.Scalars['String']['input'];
            address2?: Types.Scalars['String']['input'] | null;
            city: Types.Scalars['String']['input'];
            country: Types.Country;
            state: Types.Scalars['String']['input'];
            zipCode: Types.Scalars['String']['input'];
          };
          coordinates?: {
            __typename?: 'Coordinates';
            longitude: Types.Scalars['Float']['input'];
            latitude: Types.Scalars['Float']['input'];
          } | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetAddressesDocument = `
    query GetAddresses {
  currentUser {
    activeMember {
      shop {
        accountAddresses {
          id
          address {
            ...Address
          }
          isDefault
        }
        addresses {
          id
          address {
            ...Address
          }
          isDefault
          timeZoneId
          type
          coordinates {
            ...Coordinates
          }
        }
      }
    }
  }
}
    ${AddressFragmentDoc}
${CoordinatesFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAddresses: build.query<GetAddressesQuery, GetAddressesQueryVariables | void>({
      query: (variables) => ({ document: GetAddressesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAddressesQuery, useLazyGetAddressesQuery } = injectedRtkApi;
