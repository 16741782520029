import { useDebounce } from '@partstech/ui/hooks';
import { isString } from '@partstech/ui/utils';
import { useCallback, useEffect, useState } from 'react';
import type React from 'react';

export type UseInputValueOptions = {
  onClear?: () => void;
  onChange?: (value: string) => void;
  debounceDelay?: number;
};

/**
 * Hook to make state work for input value
 *
 * @param defaultValue
 * @param onChange
 * @param onClear
 * @param debounceDelay
 */
export const useInputValue = (
  defaultValue: string,
  { onChange, onClear, debounceDelay = 300 }: UseInputValueOptions = {}
) => {
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, debounceDelay);

  const changeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const newValue = isString(e) ? e : e.target.value;

      setValue(newValue);

      onChange && onChange(newValue);
    },
    [onChange]
  );

  const clearValue = useCallback(() => {
    setValue('');
  }, []);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value === '' && onClear) {
      onClear();
    }
  }, [onClear, value]);

  return { value, debouncedValue, changeValue, clearValue };
};
