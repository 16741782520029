import { isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import { api } from 'store/mutations/logout';
import type { MiddlewareAPI, ThunkDispatch, Action } from '@reduxjs/toolkit';
import type { ResponseError } from 'shared/api';
import type { RootState } from 'store';

const hasUnauthorizedError = (message?: string) => message === 'Unauthenticated' || message === 'Unauthorized';

export const unauthorizedMiddleware =
  <D extends ThunkDispatch<RootState, unknown, Action>, S extends RootState>(store: MiddlewareAPI<D, S>) =>
  (next: (action: unknown) => unknown) =>
  (action: unknown) => {
    if (isRejectedWithValue(action) && hasUnauthorizedError((action.payload as ResponseError).message)) {
      return store.dispatch(api.endpoints.Logout.initiate());
    }

    if (isRejected(action) && hasUnauthorizedError(action.error.message)) {
      return store.dispatch(api.endpoints.Logout.initiate());
    }

    return next(action);
  };
