import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import type { LDFlagSet } from 'types/launchdarkly';

export const useGetLaunchDarklyFlags = () => {
  const {
    attributeBasedSearch,
    availabilityFilterEnhancements,
    failedOrdersVisibility,
    jobsVisibility,
    newOnboardingWidget,
    newReportBanner,
    newWelcomeModal,
    optionsPreferences,
    postOrderEtas,
    productPageRedesign,
    promoCode,
    saveLaborInCart,
    selectedFiltersChip,
    useProductSortingLogicOnTheBackend,
    vehicleSpecificJobs,
    xlPartsTphEtasUi,
    laborRedesign,
  } = useFlags();

  const [flags, setFlags] = useState<LDFlagSet>({});

  useEffect(() => {
    setFlags({
      attributeBasedSearch,
      availabilityFilterEnhancements,
      failedOrdersVisibility,
      jobsVisibility,
      newOnboardingWidget,
      newReportBanner,
      newWelcomeModal,
      optionsPreferences,
      postOrderEtas,
      productPageRedesign,
      promoCode,
      saveLaborInCart,
      selectedFiltersChip,
      useProductSortingLogicOnTheBackend,
      vehicleSpecificJobs,
      xlPartsTphEtasUi,
      laborRedesign,
    });
  }, [
    attributeBasedSearch,
    availabilityFilterEnhancements,
    failedOrdersVisibility,
    jobsVisibility,
    newOnboardingWidget,
    newReportBanner,
    newWelcomeModal,
    postOrderEtas,
    productPageRedesign,
    promoCode,
    optionsPreferences,
    saveLaborInCart,
    selectedFiltersChip,
    useProductSortingLogicOnTheBackend,
    vehicleSpecificJobs,
    xlPartsTphEtasUi,
    laborRedesign,
  ]);

  return flags;
};
