import { TireConfiguration } from '../../models/TireConfiguration';
import type { TireSize } from 'models';

export const generateSearchInput = (
  searchQuery: string,
  isTire: boolean,
  accountId: string,
  tireSizeList: TireSize[]
) => {
  const tireSize =
    tireSizeList.find((item) => item.isMatch(searchQuery)) ?? TireConfiguration.createFromString(searchQuery);

  if (tireSize && isTire) {
    return {
      tireSize: tireSize?.toRequest({ accountId: accountId ?? '' }).tireSize,
    };
  }

  return { partNumber: { accountId, partNumber: searchQuery } };
};
