import { DEFAULT_AVAILABILITY, Routes } from 'constant';
import { UniversalPartType, type Vehicle } from 'models';
import { pickDefinedValues } from 'shared/lib/object';
import { generateUrl } from 'utils/url/generateUrl';
import { AttributeRequiredError } from '../errors/AttributeRequiredError';
import { VehicleRequiredError } from '../errors/VehicleRequiredError';
import type { SearchQuery, SearchQueryConvertable, SearchSequenceQuery } from 'features/searchForm';

export class SearchUrl {
  private readonly vehicle: Vehicle | null;

  private readonly searchEntry: SearchQueryConvertable;

  private isDealerSearch: boolean = false;

  private selectedSupplierAccountId: string | null = null;

  private searchSequenceQuery: SearchSequenceQuery | null = null;

  constructor(vehicle: Vehicle | null, searchEntry: SearchQueryConvertable) {
    this.vehicle = vehicle;
    this.searchEntry = searchEntry;
  }

  public setIsDealerSearch(value: boolean): this {
    this.isDealerSearch = value;

    return this;
  }

  public setSelectedSupplierAccountId(value: string | null): this {
    this.selectedSupplierAccountId = value;

    return this;
  }

  public setSearchSequenceQuery(searchSequenceQuery: SearchSequenceQuery): this {
    this.searchSequenceQuery = searchSequenceQuery;

    return this;
  }

  public getUrl(): string {
    if (this.searchEntry instanceof UniversalPartType && this.searchEntry.shouldPickAttributes(Boolean(this.vehicle))) {
      throw new AttributeRequiredError('Attribute required');
    }

    if (this.searchEntry.isVehicleRequired() && this.vehicle === null) {
      throw new VehicleRequiredError('Vehicle required');
    }

    const query: SearchQuery = {
      ...this.searchEntry.toQuery(this.vehicle),
      vehicle: this.vehicle?.id,
      vin: this.vehicle?.vin,
      plate: this.vehicle?.plate ?? undefined,
      state: this.vehicle?.state ?? undefined,
      availability: DEFAULT_AVAILABILITY,
      selected_distributor: this.selectedSupplierAccountId ?? undefined,
      dealers: this.isDealerSearch ? '1' : undefined,
      ...this.searchSequenceQuery,
    };

    return generateUrl(Routes.SEARCH_RESULTS, {}, pickDefinedValues(query));
  }
}
