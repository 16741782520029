import { SearchSequenceType } from 'types/search';
import type { PartType } from './PartType';
import type { Vehicle } from './Vehicle';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { SearchSequenceCreatorInterface } from 'features/searchSequence';
import type { SearchSequence } from 'types/search';

export class PartTypeList implements SearchEntryPointInterface, SearchSequenceCreatorInterface {
  private readonly partTypes: PartType[];

  private readonly title: string;

  private readonly type: SearchSequenceType;

  constructor(partTypes: PartType[], title: string = '', type: SearchSequenceType = SearchSequenceType.PartTypes) {
    this.partTypes = partTypes;
    this.title = title;
    this.type = type;
  }

  isVehicleRequired(): boolean {
    return this.partTypes.some((partType) => partType.isVehicleRequired());
  }

  populateInput(): string {
    return this.partTypes.map((partType) => partType.populateInput()).join(', ');
  }

  createSearchSequence(vehicle: Vehicle | null): SearchSequence {
    return {
      tasks: this.partTypes.map((partType) => ({
        searchParams: {
          vehicle: vehicle?.id,
          vin: vehicle?.vin ?? undefined,
          state: vehicle?.state ?? undefined,
          plate: vehicle?.plate ?? undefined,
          part_text: partType.name,
          part_text_id: partType.id,
          attributes: partType.attributes?.reduce<Record<string, string[]>>(
            (result, item) => ({ ...result, [item.name]: item.values ?? [] }),
            {}
          ),
        },
        title: partType.name,
        isCompleted: false,
      })),
      title: this.title,
      id: '',
      type: this.type,
    };
  }
}
