import { ShopAddressType } from 'shared/api';
import { BaseAddress } from './BaseAddress';
import type { BaseAddressData } from './BaseAddress';
import type { Coordinates } from 'shared/api';

type ShopAddressData = {
  type: ShopAddressType;
  coordinates?: Coordinates | null;
  timeZoneId?: string | null;
} & BaseAddressData;

export class ShopAddress extends BaseAddress {
  type: ShopAddressType;

  coordinates: Coordinates | null = null;

  timeZoneId: string | null;

  constructor(addressPayload: ShopAddressData) {
    super(addressPayload);

    this.type = addressPayload.type;
    this.coordinates = addressPayload.coordinates ?? null;
    this.timeZoneId = addressPayload.timeZoneId ?? null;
  }

  get isBillingAddress(): boolean {
    return this.type === ShopAddressType.Billing;
  }

  get isShippingAddress(): boolean {
    return this.type === ShopAddressType.Shipping;
  }
}
