import { DialogModal } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';

export const useForbiddenDialog = () =>
  useCreateModal(
    DialogModal,
    {
      children: <>You don’t currently have permission to take that action.</>,
      primaryText: 'Okay',
    },
    { customId: 'forbiddenDialog' }
  );
