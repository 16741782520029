import { BottomSheet } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { useEffect } from 'react';
import { LaborReviewSummaryInfo } from '../LaborReviewSummaryInfo';
import type { LaborRate } from 'shared/api';

type Props = {
  isScrolling: boolean;
  onAddToCart: () => void;
  totalHours: number;
  laborRates: LaborRate[];
  selectedLaborRate?: LaborRate;
  onSelectLaborRate: (laborRateId: string) => void;
  isAdding?: boolean;
};

export const LaborReviewSummaryBottomSheet = ({
  onAddToCart,
  isScrolling,
  totalHours,
  laborRates,
  selectedLaborRate,
  onSelectLaborRate,
  isAdding,
}: Props) => {
  const { isToggle, toggle, turnOff } = useToggle(true);

  useEffect(() => {
    if (isToggle && isScrolling) {
      turnOff();
    }
  }, [isScrolling, isToggle, turnOff]);

  return (
    <BottomSheet
      mode="expandable"
      title="Summary"
      isExpanded
      height={isToggle ? 288 : 60}
      onExpandToggle={toggle}
      elevation={10}
      disableDivider
    >
      <LaborReviewSummaryInfo
        onAddToCart={onAddToCart}
        totalHours={totalHours}
        laborRates={laborRates}
        selectedLaborRate={selectedLaborRate}
        onSelectLaborRate={onSelectLaborRate}
        isAdding={isAdding}
      />
    </BottomSheet>
  );
};
