import { sortBy, uniq } from '@partstech/ui/utils';
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { createStoreFromData } from 'factories';
import { getStores, getSupplierStores, searchStores, searchTireStores } from 'shared/api/rest/gen/shop';
import { getMissingSuppliers, selectSupplierById } from 'store/entities/supplier';
import { createAppAsyncThunk, selectRootState } from 'store/utils';
import type { EntityId } from '@reduxjs/toolkit';
import type { Coordinates, Country } from 'shared/api';
import type { SearchStoresQuery, Store } from 'shared/api/rest/gen/shop';

type GetStoresBySupplierIdQuery = {
  supplierId: string;
  keyword?: string;
  from?: number;
  storeId?: number;
};

const selectState = createSelector([selectRootState], (state) => state.entities.store);

type AllSuppliersSearchResponse = { center?: Coordinates; partStores: Store[]; tireStores: Store[] };
type AllSuppliersSearchPayload = {
  query: SearchStoresQuery;
  searchType: 'parts' | 'tires';
  sortedByNational: boolean;
  country?: Country;
  state?: string;
};

export const getAllSuppliersSearch = createAppAsyncThunk<AllSuppliersSearchResponse, AllSuppliersSearchPayload>(
  'entities/store/getAllSuppliersSearch',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { searchType, query, country, state, sortedByNational } = payload;

      let response: AllSuppliersSearchResponse = { partStores: [], tireStores: [] };

      if (searchType === 'parts') {
        const { center, stores } = await searchStores(query);

        const sortedStoreByDistance = sortBy(stores, 'distance');

        const sortedStoreByNational = sortedByNational
          ? sortBy(sortedStoreByDistance, (store) => (store.national ? -1 : 1))
          : sortedStoreByDistance;

        response = {
          ...response,
          center,
          partStores: sortedStoreByNational,
        };
      }

      if (searchType === 'tires') {
        const tireStores = await searchTireStores({ q: query?.q, country, state });

        response = { ...response, tireStores };
      }

      const { partStores, tireStores } = response;

      const supplierIds = [...partStores, ...tireStores].map((store) => String(store.supplierId));

      await dispatch(getMissingSuppliers(supplierIds));

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStoresBySupplierId = createAsyncThunk(
  'entities/store/getStoresBySupplierId',
  ({ supplierId, ...rest }: GetStoresBySupplierIdQuery) => getSupplierStores(Number(supplierId), rest)
);

const getStoresByIds = createAsyncThunk('entities/store/getStoresByIds', (ids: number[]) => getStores({ ids }));

export const getMissingStores = createAppAsyncThunk(
  'entities/store/getMissingStores',
  (payload: number[], { dispatch, getState }) => {
    const state = getState();

    const { ids: loadedIds, loadingIds } = state.entities.store;

    const ids = uniq([...loadedIds, ...loadingIds]);

    const searchStoresIds = uniq(payload.filter((id) => !ids.includes(id)));

    if (searchStoresIds.length === 0) {
      return;
    }

    dispatch(getStoresByIds(searchStoresIds));
  }
);

const storeAdapter = createEntityAdapter<Store, EntityId>({ selectId: (entity) => entity.id });

const initialState = storeAdapter.getInitialState<State>({ loadingIds: [] });

type State = {
  loadingIds: number[];
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStoresByIds.pending, (state, action) => {
      state.loadingIds = [...state.loadingIds, ...action.meta.arg];
    });

    builder.addCase(getStoresByIds.fulfilled, (state, action) => {
      const stores = action.payload.map((store) => ({ ...store, supplier: null }));

      storeAdapter.upsertMany(state, stores);

      state.loadingIds = state.loadingIds.filter((id) => !action.meta.arg.includes(id));
    });

    builder.addCase(getAllSuppliersSearch.fulfilled, (state, action) => {
      const { partStores, tireStores } = action.payload;

      const stores = [...partStores, ...tireStores].map((store) => ({ ...store, supplier: null }));

      storeAdapter.upsertMany(state, stores);
    });

    builder.addCase(getStoresBySupplierId.fulfilled, (state, action) => {
      const stores = action.payload.map((store) => ({ ...store, supplier: null }));

      storeAdapter.upsertMany(state, stores);
    });
  },
});

export const store = storeSlice.reducer;

const { selectAll } = storeAdapter.getSelectors(selectState);

const selectAllStores = createSelector([selectRootState, selectAll], (rootState, entities) =>
  entities.map((entity) => createStoreFromData(entity, selectSupplierById(rootState, String(entity.supplierId))))
);

export const selectLoadingIds = createSelector(selectState, (state) => state.loadingIds);

export const selectStoresBySupplierId = createSelector(
  [selectAllStores, (_: unknown, supplierId: string | null) => supplierId],
  (entities, supplierId) => (supplierId ? entities.filter((entity) => entity.supplier?.id === supplierId) : [])
);

export const selectStoresBySupplierIdWithLimit = createSelector(
  [selectRootState, (_, params: { supplierId: string; limit: number }) => params],
  (rootState, { supplierId, limit }) => {
    const entities = selectStoresBySupplierId(rootState, supplierId);

    return sortBy(entities, 'distance').slice(0, limit);
  }
);
