import { css } from '@emotion/react';
import { BottomSheet, Box, Icon, IconButton, ImageFallback, Scrollbar, useMedia, Modal } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { VehicleString } from 'components/VehicleString';
import motorLogo from 'images/content/maintenance/motor-driven.png';
import type { Theme } from '@partstech/ui';
import type { Vehicle } from 'models';
import type { PropsWithChildren, ReactNode } from 'react';

const styles = {
  content: (theme: Theme) => css`
    background: ${theme.color.mono2};
    height: calc(100vh - 51px);
  `,
};

type Props = {
  vehicle: Vehicle | null;
  onClose: () => void;
  summary: ReactNode;
  onScroll: () => void;
};

export const LaborReviewModalLayout = ({ onClose, vehicle, children, summary, onScroll }: PropsWithChildren<Props>) => {
  const { isMobile } = useMedia();
  const { isToggle, turnOff, turnOn } = useToggle(false);

  return (
    <Modal
      id="reviewModal"
      clickAwayConfig={{ isDisabled: true }}
      title="Review"
      actions={
        <>
          {isMobile && (
            <IconButton onClick={turnOn}>
              <Icon name="directions_car" color="subtleText" />
            </IconButton>
          )}
          <ImageFallback src={motorLogo} height={24} />
        </>
      }
      onClose={onClose}
      closeIcon="arrow_back"
      size="fullScreen"
    >
      <Scrollbar onScrollY={onScroll} css={styles.content}>
        <Box
          maxWidth={{ md: 270 }}
          mx="auto"
          pt={{ sm: 4, md: 6 }}
          pb={{ sm: 16, md: 6 }}
          px={2}
          display={{ sm: 'block', md: 'flex' }}
          gap={6}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box flexGrow={1}>{children}</Box>

          {summary}
        </Box>
      </Scrollbar>

      {isToggle && vehicle && (
        <BottomSheet isClosable onClose={turnOff}>
          <VehicleString vehicles={[vehicle]} multiline />
        </BottomSheet>
      )}
    </Modal>
  );
};
