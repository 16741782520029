import { isEmpty, uniq } from '@partstech/ui/utils';
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { createSupplierFromData } from 'factories';
import { getSuppliers as fetchSuppliers, getSupplierProfile } from 'shared/api/rest/gen/shop';
import { selectRootState, createAppAsyncThunk } from 'store/utils';
import type { Supplier as SupplierType } from 'shared/api/rest/gen/shop';

const selectState = createSelector([selectRootState], (state) => state.entities.supplier);

export const getSupplierBySlug = createAsyncThunk('entities/supplier/getSupplierBySlug', (slug: string) =>
  getSupplierProfile(slug)
);

const getSuppliersByIds = createAsyncThunk('entities/supplier/getSuppliersByIds', (ids: number[]) =>
  fetchSuppliers({ ids })
);

export const getMissingSuppliers = createAppAsyncThunk<null, string[]>(
  'entities/supplier/getMissingSuppliers',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState();

      const { ids: loadedIds, loadingIds } = state.entities.supplier;

      const ids = uniq([...loadedIds, ...loadingIds]).map(String);

      const searchSupplierIds = uniq(payload.filter((supplierId) => !ids.includes(supplierId))).map(Number);

      if (!isEmpty(searchSupplierIds)) {
        await dispatch(getSuppliersByIds(searchSupplierIds));
      }

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const supplierAdapter = createEntityAdapter<SupplierType, string>({ selectId: (entity) => String(entity.id) });

type State = {
  loadingIds: number[];
};

const supplierSlice = createSlice({
  name: 'supplier',
  initialState: supplierAdapter.getInitialState<State>({ loadingIds: [] }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupplierBySlug.fulfilled, supplierAdapter.upsertOne);

    builder.addCase(getSuppliersByIds.pending, (state, action) => {
      state.loadingIds = [...state.loadingIds, ...action.meta.arg];
    });

    builder.addCase(getSuppliersByIds.fulfilled, (state, action) => {
      supplierAdapter.upsertMany(state, action);

      state.loadingIds = state.loadingIds.filter((id) => !action.meta.arg.includes(id));
    });
  },
});

export const supplier = supplierSlice.reducer;

const { selectAll, selectById } = supplierAdapter.getSelectors(selectState);

export const selectSupplierById = createSelector([selectRootState, (_, id: string | null) => id], (state, id) => {
  const entity = selectById(state, id ?? '');

  return entity ? createSupplierFromData(entity) : null;
});

const selectAllSuppliers = createSelector(selectAll, (entities) => entities.map(createSupplierFromData));

export const selectSupplierBySlug = createSelector(
  [selectAllSuppliers, (_: unknown, slug: string) => slug],
  (entities, slug) => entities.find((entity) => entity.slug === slug) ?? null
);
