import { InvitationStatus } from 'shared/api';
import type { MemberPermissions } from './MemberPermissions';

type InvitationData = {
  id: string;
  email: string;
  sentDate: string;
  status: InvitationStatus;
  permissions: MemberPermissions;
};

export class MemberInvitation {
  id: string;

  email: string;

  sentDate: string;

  status: InvitationStatus;

  permissions: MemberPermissions;

  constructor(invitation: InvitationData) {
    this.id = invitation.id;
    this.email = invitation.email;
    this.sentDate = invitation.sentDate;
    this.status = invitation.status;
    this.permissions = invitation.permissions;
  }

  get isAccepted(): boolean {
    return this.status === InvitationStatus.Accepted;
  }

  get isCanceled(): boolean {
    return this.status === InvitationStatus.Canceled;
  }

  get isExpired(): boolean {
    return this.status === InvitationStatus.Expired;
  }

  get isSent(): boolean {
    return this.status === InvitationStatus.Sent;
  }
}
