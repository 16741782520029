import { User } from '../models/User';

type UserPayload = {
  id: string;
  avatar?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
};

export const createUserFromData = (userPayload: UserPayload) => new User(userPayload);
