import { AvailabilityLine } from './AvailabilityLine';
import type { IconName } from '@partstech/ui';

export class EnhancementAvailabilityLine extends AvailabilityLine {
  getIcon(): IconName | undefined {
    if (this.isClosest()) {
      return undefined;
    }

    return 'storefront';
  }

  getNetworkAvailabilityTitle() {
    return `In Network (${this.formattedMaxQuantity})`;
  }

  getClosestAvailabilityTitle(): string {
    return `Direct Delivery (${this.formattedMaxQuantity})`;
  }
}
