import { useCallback } from 'react';
import { useUtmParams } from 'hooks/signup';
import { GOOGLE_ONE_TAP_CONTAINER_ID, useGoogleOneTapLogin } from 'integrations/google';
import { useMutationCallback } from 'shared/api';
import { useCreateUserByGoogleOneTapMutation } from 'store/mutations/createUserByGoogleOneTap/CreateUserByGoogleOneTap.generated';
import type { IGoogleEndPointResponse } from 'integrations/google';
import type { UserAccountStatus } from 'shared/api';
import type { CreateUserByGoogleOneTapMutation } from 'store/mutations/createUserByGoogleOneTap/CreateUserByGoogleOneTap.generated';

type Args = {
  clientId: string;
  onSuccess: (accountStatus?: UserAccountStatus) => Promise<void>;
};

export const useGoogleOneTap = ({ clientId, onSuccess }: Args) => {
  const { utmEntries } = useUtmParams();

  const handleCreateUserSuccess = useCallback(
    (data: CreateUserByGoogleOneTapMutation) => onSuccess(data?.createUser?.accountStatus ?? undefined),
    [onSuccess]
  );

  const [createUser] = useMutationCallback(useCreateUserByGoogleOneTapMutation, {
    onSuccess: handleCreateUserSuccess,
  });

  const handleCreateUser = useCallback(
    async (response: IGoogleEndPointResponse) => {
      const firstName = response.given_name || response.email.split('@')[0] || '';

      await createUser({
        input: {
          email: response.email,
          firstName,
          lastName: response.family_name || null,
          utmParams: utmEntries,
        },
      });
    },
    [createUser, utmEntries]
  );

  useGoogleOneTapLogin({
    onSuccess: handleCreateUser,
    googleAccountConfigs: {
      client_id: clientId,
      prompt_parent_id: GOOGLE_ONE_TAP_CONTAINER_ID,
      cancel_on_tap_outside: false,
      context: 'signup',
    },
  });
};
