import { useMemo, useState } from 'react';
import { SupplierSelectorContextProvider } from './SupplierSelectorContext';
import { useSelectedSupplierAccountId } from './useSelectedSupplierAccountId';
import type { PropsWithChildren } from 'react';

export const SupplierSelectorProvider = ({ children }: PropsWithChildren) => {
  const [isSticky, setIsSticky] = useState(false);

  const { selectedSupplierAccountId, setSelectedSupplierAccountId } = useSelectedSupplierAccountId();

  const value = useMemo(
    () => ({ isSticky, setIsSticky, selectedSupplierAccountId, setSelectedSupplierAccountId }),
    [isSticky, selectedSupplierAccountId, setSelectedSupplierAccountId]
  );

  return <SupplierSelectorContextProvider value={value}>{children}</SupplierSelectorContextProvider>;
};
