import { useState, useMemo } from 'react';
import { checkModuleError } from 'shared/lib/bundling';
import type { ResponseError } from 'shared/api';

export const useErrorBoundary = () => {
  const [error, setError] = useState<(Error & ResponseError) | null>(null);

  const askRefresh = useMemo(() => {
    if (error) {
      return checkModuleError(error);
    }

    return false;
  }, [error]);

  return { askRefresh, setError };
};
