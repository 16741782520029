import { useCallback, useState } from 'react';
import { useSearchParams } from 'hooks/search';

// TODO: Replace after completing task PD-5879
export const useSelectedSupplierAccountId = () => {
  const { selectedSupplierId } = useSearchParams();

  const [selectedSupplierAccountId, setSelectedSupplierAccountId] = useState<string | null>(null);

  const getSelectedSupplierAccountId = useCallback(() => {
    if (selectedSupplierId) {
      return selectedSupplierId;
    }

    return selectedSupplierAccountId;
  }, [selectedSupplierAccountId, selectedSupplierId]);

  return {
    selectedSupplierAccountId: getSelectedSupplierAccountId(),
    setSelectedSupplierAccountId,
  };
};
