import { client } from 'shared/api/rest/client';
import type { Config } from 'shared/api/rest/client';

export function login(body: LoginRequest, config?: Config): Promise<LoginResponse> {
  return client.post(`/api/login`, body, config);
}

export function googleAuthUri(config?: Config): Promise<string> {
  return client.get(`/api/google/auth`, config);
}

export function googleLogin(query: GoogleLoginQuery, config?: Config): Promise<void> {
  return client.get(`/api/google/login`, { params: query, ...config });
}

export function getActiveCartOrders(config?: Config): Promise<GetActiveCartOrdersResponse> {
  return client.get(`/api/active-cart/orders`, config);
}

export function getActiveCartOrderById(orderId: number, config?: Config): Promise<GetActiveCartOrderByIdResponse> {
  return client.get(`/api/active-cart/orders/${orderId}`, config);
}

export function deleteActiveCartOrder(orderId: number, config?: Config): Promise<void> {
  return client.delete(`/api/active-cart/orders/${orderId}`, config);
}

export function updateActiveCartOrderShippingMethod(
  orderId: number,
  body: UpdateActiveCartOrderShippingMethodRequest,
  config?: Config
): Promise<void> {
  return client.put(`/api/active-cart/orders/${orderId}/shipping-method`, body, config);
}

export function updateActiveCartOrderAdditionalFields(
  orderId: number,
  body: UpdateActiveCartOrderAdditionalFieldsRequest,
  config?: Config
): Promise<void> {
  return client.put(`/api/active-cart/orders/${orderId}/additional-fields`, body, config);
}

export function updateActiveCartOrderPaymentNotes(
  orderId: number,
  body: UpdateActiveCartOrderPaymentNotesRequest,
  config?: Config
): Promise<void> {
  return client.put(`/api/active-cart/orders/${orderId}/payment-notes`, body, config);
}

export function updateActiveCartItem(
  itemId: number,
  body: UpdateActiveCartItemRequest,
  config?: Config
): Promise<void> {
  return client.put(`/api/active-cart/items/${itemId}`, body, config);
}

export function deleteActiveCartItem(itemId: number, config?: Config): Promise<void> {
  return client.delete(`/api/active-cart/items/${itemId}`, config);
}

export function addItemInActiveCart(body: AddItemRequest, config?: Config): Promise<AddItemResponse> {
  return client.post(`/api/active-cart/items`, body, config);
}

export function batchAddItemInActiveCart(
  body: BatchAddItemInActiveCartRequest,
  config?: Config
): Promise<AddItemResponse[]> {
  return client.post(`/api/active-cart/items/batch`, body, config);
}

export function buyActiveCart(body: BuyActiveCartRequest, config?: Config): Promise<BuyActiveCartResponse> {
  return client.post(`/api/active-cart/buy`, body, config);
}

export function submitActiveCart(body: SubmitActiveCartRequest, config?: Config): Promise<SubmitActiveCartResponse> {
  return client.post(`/api/active-cart/submit-quote`, body, config);
}

export function mitchell1BuyActiveCart(
  body: Mitchell1BuyActiveCartRequest,
  config?: Config
): Promise<Mitchell1ShoppingCart> {
  return client.post(`/api/active-cart/mitchell1/buy`, body, config);
}

export function mitchell1SubmitActiveCart(
  body: Mitchell1SubmitActiveCartRequest,
  config?: Config
): Promise<Mitchell1ShoppingCart> {
  return client.post(`/api/active-cart/mitchell1/submit-quote`, body, config);
}

export function productDetails(partnumberid: string, query: ProductDetailsQuery, config?: Config): Promise<Product> {
  return client.get(`/api/details/${partnumberid}`, { params: query, ...config });
}

export function getFsStats(config?: Config): Promise<GetFsStatsResponse> {
  return client.get(`/api/fs-stats`, config);
}

export function searchGetVehicle(query: SearchGetVehicleQuery, config?: Config): Promise<Vehicle[]> {
  return client.get(`/api/vehicles`, { params: query, ...config });
}

export function searchTypeahead(query: SearchTypeaheadQuery, config?: Config): Promise<SearchTypeaheadResponse> {
  return client.get(`/api/search/typeahead`, { params: query, ...config });
}

export function searchGetTiresSize(config?: Config): Promise<SearchGetTiresSizeResponse> {
  return client.get(`/api/search/tires/sizes`, config);
}

export function searchResultsPartsSearch(
  query: SearchResultsPartsSearchQuery,
  config?: Config
): Promise<SearchResultsPartsSearchResponse> {
  return client.get(`/api/search/parts`, { params: query, ...config });
}

export function getStore(id: number, config?: Config): Promise<Store> {
  return client.get(`/api/stores/${id}`, config);
}

export function getSuppliers(query: GetSuppliersQuery, config?: Config): Promise<Supplier[]> {
  return client.get(`/api/suppliers`, { params: query, ...config });
}

export function getSupplierProfile(slug: string, config?: Config): Promise<Supplier> {
  return client.get(`/api/suppliers/slug/${slug}`, config);
}

export function getStores(query: GetStoresQuery, config?: Config): Promise<Store[]> {
  return client.get(`/api/stores`, { params: query, ...config });
}

export function searchStores(query: SearchStoresQuery, config?: Config): Promise<SearchStoresResponse> {
  return client.get(`/api/suppliers/stores`, { params: query, ...config });
}

export function searchTireStores(query: SearchTireStoresQuery, config?: Config): Promise<Store[]> {
  return client.get(`/api/suppliers/tire-stores`, { params: query, ...config });
}

export function getSupplierStores(
  supplierId: number,
  query: GetSupplierStoresQuery,
  config?: Config
): Promise<Store[]> {
  return client.get(`/api/suppliers/${supplierId}/stores`, { params: query, ...config });
}

export function credentialsHelp(config?: Config): Promise<void> {
  return client.post(`/api/profile/credentials/help-request`, null, config);
}

export function logout(config?: Config): Promise<void> {
  return client.post(`/api/logout`, null, config);
}

export function checkMitchell1Token(
  body: CheckMitchell1TokenRequest,
  config?: Config
): Promise<CheckMitchell1TokenResponse> {
  return client.post(`/api/mitchell1/setup/check`, body, config);
}

export function refreshMitchell1Token(
  body: RefreshMitchell1TokenRequest,
  config?: Config
): Promise<RefreshMitchell1TokenResponse> {
  return client.post(`/api/mitchell1/setup/refresh`, body, config);
}

export function generateMitchell1Token(
  body: GenerateMitchell1TokenRequest,
  config?: Config
): Promise<GenerateMitchell1TokenResponse> {
  return client.post(`/api/mitchell1/setup/auth`, body, config);
}

export function batchGetLabor(body: BatchGetLaborRequest, config?: Config): Promise<BatchGetLaborResponse> {
  return client.post(`/api/labor/batch`, body, config);
}

export type LoginRequest = {
  password: string;
  rememberMe: boolean;
  username: string;
};

export type LoginResponse = {
  success: boolean;
  url: string;
};

export type GoogleLoginQuery = {
  code: string;
};

export type GetActiveCartOrdersResponse = {
  orders: Shipment[];
};

export type GetActiveCartOrderByIdResponse = {
  order: Shipment;
};

export type UpdateActiveCartOrderShippingMethodRequest = {
  shippingMethodCode: string;
};

export type UpdateActiveCartOrderAdditionalFieldsRequest = {
  additionalFields: {
    name: string;
    value: string;
  }[];
};

export type UpdateActiveCartOrderPaymentNotesRequest = {
  PONumber: string | null;
  customNotes: string | null;
};

export type UpdateActiveCartItemRequest = {
  quantity: number;
};

export type BatchAddItemInActiveCartRequest = {
  items: AddItemRequest[];
};

export type BuyActiveCartRequest = {
  orderRequests: {
    orderId: number;
    shippingMethodCode: string;
  }[];
  submittedLabors?: SubmittedLabor[];
};

export type BuyActiveCartResponse = {
  orders: Shipment[];
  paidOrders: Shipment[];
  redirectUrl: string | null;
};

export type SubmitActiveCartRequest = {
  orderRequests: {
    orderId: number;
    shippingMethodCode: string;
  }[];
  submittedLabors?: SubmittedLabor[];
};

export type SubmitActiveCartResponse = {
  errorMessage: string | null;
  redirectUrl: string | null;
  submitted: boolean;
};

export type Mitchell1BuyActiveCartRequest = {
  orderRequests: {
    orderId: number;
    shippingMethodCode: string;
  }[];
  submittedLabors?: SubmittedLabor[];
};

export type Mitchell1SubmitActiveCartRequest = {
  orderRequests: {
    orderId: number;
    shippingMethodCode: string;
  }[];
  submittedLabors?: SubmittedLabor[];
};

export type ProductDetailsQuery = {
  supplierId?: number | null;
  credentialId?: number | null;
  vehicle?: number | null;
  part_category?: string | null;
  part_sub_category?: string | null;
  part_term?: number | null;
  vin?: string | null;
  plate?: string | null;
  state?: string | null;
  linecard?: number | null;
  store?: number | null;
  variation?: string | null;
  part_text?: string | null;
  part_text_id?: string | null;
  quote?: number | null;
};

export type GetFsStatsResponse = {
  smsValidatedDate: string | null;
};

export type SearchGetVehicleQuery = {
  ids: string | number[];
};

export type SearchTypeaheadResponse = {
  isPartNumber: boolean;
  keywords: {
    aliases: string | null;
    id: string;
    name: string;
    partTypes: PartType[];
    priority: number;
  }[];
  partNumbers: {
    companyId: number | null;
    companyName: string;
    partNumber: string;
    partNumberId: string;
  }[];
  suggestions: PartType[];
};

export type SearchTypeaheadQuery = {
  q: string;
};

export type SearchGetTiresSizeResponse = {
  height: number;
  rim: number;
  width: number;
}[];

export type SearchResultsPartsSearchResponse = {
  errors: string[];
  parts: Product[];
};

export type SearchResultsPartsSearchQuery = {
  supplierId?: number | null;
  credentialId?: number | null;
  vehicle?: number;
  part_types?: string;
  part_text?: string;
  part_text_id?: string;
  part_category?: number;
  part_sub_category?: number;
  partnumber?: string;
  vin?: string;
  tire_sizes?: string;
  tire_id?: string;
  debug?: boolean;
  attributes?: Record<string, string[]>;
  universal?: 0 | 1;
};

export type GetSuppliersQuery = {
  ids: string | number[];
};

export type GetStoresQuery = {
  ids: string | number[];
};

export type SearchStoresResponse = {
  center: Coordinates;
  stores: Store[];
};

export type SearchStoresQuery = {
  q?: string;
  lat?: number;
  lng?: number;
  box?: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
};

export type SearchTireStoresQuery = {
  q?: string;
  country?: 'US' | 'CA' | 'MX';
  state?: string;
};

export type GetSupplierStoresQuery = {
  keyword?: string;
  from?: number;
  storeId?: number;
};

export type CheckMitchell1TokenRequest = {
  token: string;
};

export type CheckMitchell1TokenResponse = {
  username: string;
};

export type RefreshMitchell1TokenRequest = {
  token: string;
};

export type RefreshMitchell1TokenResponse = {
  token: string;
};

export type GenerateMitchell1TokenRequest = {
  apiKey: string;
  login: string;
};

export type GenerateMitchell1TokenResponse = {
  token: string;
};

export type BatchGetLaborRequest = {
  procedureDetails: {
    mitchell1VehicleId?: string | null;
    partTypeId: number;
    vehicleId: number;
  }[];
};

export type BatchGetLaborResponse = {
  procedureDetails: LaborProcedureDetail[];
};

export type AddItemRequest = {
  availabilityBranch?: string | null;
  credentialsId: number;
  lineCardId: number;
  origin?: 'UNDEFINED' | 'SEARCH' | 'STOCK_ORDER' | 'API' | null;
  partName?: string;
  partNumber: string;
  partNumberId: string;
  partTermId?: number | null;
  quantity: number;
  quote?: number;
  searchResultPosition?: number | null;
  searchedPartNumber?: string | null;
  storeId: number;
  vehicleId?: number | null;
  vin?: string | null;
};

export type AddItemResponse = {
  error?: Error | null;
  orderId?: number | null;
  orderItemId?: number | null;
  partNumberId: string;
  status: number;
};

export type AdditionalInformationField = {
  internalName: string;
  label: string;
  required: boolean;
  type: 'text' | 'phone' | 'email';
  value: string | null;
};

export type Address = {
  address1: string;
  address2: string | null;
  city: string;
  country?: 'US' | 'CA' | 'MX';
  state: string;
  zipCode: string;
};

export type Card = {
  brand: 'Visa' | 'MasterCard' | 'Discover' | 'AmericanExpress';
  canRemove: boolean;
  expMonth: number;
  expYear: number;
  id: number;
  name: string;
  owner: string | null;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type CredentialField = {
  helper: string | null;
  internalName: string;
  label: string;
  link: string | null;
  options: string[];
  required: boolean;
  type: 'text' | 'password' | 'select' | 'checkbox';
  validationRules: {
    inputType: 'any' | 'numeric' | 'alphabetic' | 'email';
    length: string | null;
  } | null;
  value: string | null;
};

export type Credentials = {
  additionalInformation?: CredentialsAdditionalInformation;
  credentials: CredentialField[];
  customerDescription: string;
  id: number;
  nickname?: string | null;
  priority: number;
  status: 0 | 1 | 2 | 20 | 24 | 25 | 30 | 40 | 45;
  storeId: number | null;
  supplierId: number;
};

export type CredentialsAdditionalInformation = {
  description: string | null;
  fields: AdditionalInformationField[];
  title: string;
};

export type Diagram = {
  brandId: number;
  brandName: string;
  id: string;
  partTypes: PartType[];
  url: string;
};

export type Error = {
  errors: {
    code: number;
    field: string | null;
    message: string;
  }[];
};

export type ErrorLine = {
  code: number;
  field: string | null;
  message: string;
};

export type FreightTermGroup = {
  shippingCharge: number;
  shippingMethod: string;
  shippingMethodCode: string;
  shippingTax: number;
};

export type Labor = Record<string, LaborLine>;

export type LaborLine = {
  duration: number;
  id: string;
  name: string;
  notes: string;
  parent_id: string | null;
  skill: string;
};

export type LaborProcedureDetail = {
  availableMitchell1Vehicles: {
    bodyStyle: string;
    country: string;
    driveType: string;
    engine: string;
    engineCode: string;
    fuelType: string;
    id: string;
    make: string;
    mitchell1VehicleId: string;
    model: string;
    submodel: string;
    transferCaseType: string;
    transmissionCode: string;
    transmissionControlType: string;
  }[];
  error: {
    code: number;
    message: string;
  } | null;
  mitchell1VehicleId: string | null;
  operationProcedures: {
    error: {
      code: number;
      message: string;
    } | null;
    operation: {
      component: string;
      group: string;
      id: number;
      operation: string;
      subgroup?: string;
    };
    procedure: {
      applicationGroups: {
        additionalProcedures: LaborProcedureInformation[];
        applicationGroupName: string;
        combinationProcedures: LaborProcedureInformation[];
        items: LaborProcedureItem[];
        procedureNote: string | null;
      }[];
      component: string;
      group: string;
      operation: string;
      skillLevel: string | null;
      subgroup: string;
    } | null;
  }[];
  partTypeId: number;
  vehicleId: number | null;
};

export type LaborProcedureInformation = {
  component: string;
  items: LaborProcedureItem[];
  operation: string;
  procedureNote: string | null;
  skillLevel: string | null;
};

export type LaborProcedureItem = {
  additionalApplication: string | null;
  applicationNote: string | null;
  key: string;
  standardTime: number;
  vehicleApplication: string | null;
  warrantyTime: number;
};

export type Mitchell1Order = {
  ConfirmationNumber?: string;
  DeliveryOptions?: string;
  OrderMessage?: string;
  Parts?: Mitchell1OrderPart2[];
  ReservedPONumber?: string | null;
  TrackingNumber?: string | null;
  type?: string;
};

export type Mitchell1OrderPart2 = {
  Description: string;
  LocationId: string;
  LocationName: string;
  ManufacturerLineCode: string;
  ManufacturerName: string;
  Metadata?: string | null;
  PartCategory?: string | null;
  PartNumber: string;
  QuantityAvailable: number;
  QuantityOrdered: number;
  QuantityRequested: number;
  ShippingCost?: number | null;
  ShippingDescription?: string | null;
  Size?: string | null;
  Status: string;
  SupplierName?: string | null;
  UnitCore: number;
  UnitCost: number;
  UnitList: number;
  type: string;
};

export type Mitchell1PartItem2 = {
  Description: string;
  IsTire: boolean;
  ManufacturerLineCode: string;
  ManufacturerName: string;
  Metadata?: string | null;
  PartCategory?: string | null;
  PartNumber: string;
  Quantity: number;
  ShippingCost?: number | null;
  ShippingDescription?: string | null;
  Size?: string | null;
  SupplierName?: string | null;
  UnitCore: number;
  UnitCost: number;
  UnitList: number;
  UpcCode?: string | null;
  type: string;
};

export type Mitchell1ShoppingCart = {
  labors: {
    Description: string;
    Hours: number;
    Price: number | null;
    type: string;
  }[];
  notes: {
    Note: string;
    type: string;
  }[];
  orders: Mitchell1Order[];
  partItems: Mitchell1PartItem2[];
};

export type Order = {
  billingAddress: Address;
  canMarkAsDelivered: boolean;
  card: {
    brand: 'Visa' | 'MasterCard' | 'Discover' | 'AmericanExpress' | 'JCB' | 'Diners Club' | 'UnionPay';
    last4: number;
  } | null;
  confirmationNumbers: string;
  credentialId: number | null;
  customNotes: string | null;
  customerNumber: string | null;
  datePaid: string | null;
  discountTotal: number;
  fetChargeTotal: number;
  grandTotal: number;
  hash: string;
  id: number;
  lastTrackingDate: string | null;
  lines: OrderItem[];
  paymentWay: 'WHOLESALE' | 'CREDIT_CARD';
  purchaseOrderNumber: string;
  shippingAddress: Address;
  shippingMethod: string;
  shippingTotal: number;
  shippings: {
    trackingNumber: string;
    trackingUrl: string;
  }[];
  shopId: number;
  storeId: number;
  supplierId?: number;
  taxTotal: number;
  trackingStatus:
    | 'ORDER_PLACED'
    | 'ACKNOWLEDGED'
    | 'CONFIRMED'
    | 'SHIPPING'
    | 'SHIPPED'
    | 'DELIVERED'
    | 'CANCELLED'
    | 'RETURNED';
  user: {
    firstName: string;
    lastName: string;
  };
  userId: number;
};

export type OrderItem = {
  bundled: boolean;
  coreCharge: number | null;
  id: number;
  image: string | null;
  partName: string | null;
  partNumber: string;
  price: number;
  quantity: number;
  returnAction: 'CANCEL' | 'RETURN' | null;
  returnId: number | null;
  rewardPoints: number | null;
  status:
    | 'NEW'
    | 'NOT_SHIPPED'
    | 'SHIPPED'
    | 'DELIVERED'
    | 'CANCEL_INITIATED'
    | 'CANCEL_APPROVED'
    | 'CANCELLED'
    | 'RETURN_INITIATED'
    | 'RETURN_APPROVED'
    | 'RETURNED';
  subtotal: number;
  supplierNotes: string | null;
  totalDiscount: number;
  trackingNumbers: string[];
  trackingUrl: string | null;
  urlParams: ProductUrlParams;
  vehicle: Vehicle | null;
};

export type OtherImage = {
  filename: string;
  full: string;
  isAttachment: boolean;
  isVideo: boolean;
  medium: string | null;
  name: string | null;
  preview: string;
};

export type PartImage = {
  full: string;
  isAttachment: boolean;
  isVideo: boolean;
  medium: string | null;
  preview: string;
} | null;

export type PartImages = {
  image: string;
  image360Groups: OtherImage[][];
  manufacturer: string | null;
  other: OtherImage[];
  partImage: PartImage;
  supplier: string;
};

export type PartType = {
  aliases: string | null;
  application: 'FITTED' | 'UNIVERSAL_FITTED' | 'UNIVERSAL';
  attributes: boolean;
  categories: {
    categoryId: number;
    subCategoryId: number;
  }[];
  id: number;
  name: string;
};

export type PaymentHistoryCard = {
  brand: 'Visa' | 'MasterCard' | 'Discover' | 'AmericanExpress';
  expMonth: number;
  expYear: number;
  name: string;
  owner: string | null;
};

export type PaymentMethod = 'WHOLESALE' | 'CREDIT_CARD';

export type Product = {
  assets: {
    diagrams: {
      full: string;
      name: string;
      preview: string;
    }[];
    digital: {
      files: {
        filename: string;
        full: string;
      }[];
      name: string;
    }[];
  };
  attributes: RawAttribute[];
  brandId: number | null;
  brandLineCode: string | null;
  brandLogo: string | null;
  brandName: string | null;
  bundleAvailable: boolean;
  companyCopyright: string | null;
  coreCharge: number | null;
  credentialId: number | null;
  debugMessages: string[];
  descriptions: {
    code: string;
    label: string;
    value: string[];
  }[];
  diagrams: Diagram[];
  extendedInformation: Record<string, string>;
  fetCharge: number | null;
  hasVehicleFitment: boolean;
  id: string;
  images: PartImages;
  interchangePart: {
    brand: string | null;
    partNumber: string;
    type: 'INTCH' | 'SUPERSEDE' | 'ALTERNATE';
  } | null;
  lineCardId: number | null;
  links: {
    name: string;
    url: string;
  }[];
  listPrice: number | null;
  mapPrice: number | null;
  marketingDescriptions: string[];
  marketingSections: {
    header: string | null;
    items: string[];
    sectionName: string;
  }[];
  notes: string[][];
  notesOem: string[];
  packages: {
    merchandising: {
      height: number;
      length: number;
      width: number;
    } | null;
    shipping: {
      height: number;
      length: number;
      width: number;
    } | null;
    weight: number | null;
  } | null;
  partNumber: string;
  partNumberId: string;
  partNumbers: {
    company: string | null;
    has_data: boolean | null;
    interchange: boolean;
    value: string;
  }[];
  partTypeId: number | null;
  partTypeName: string | null;
  price: number | null;
  productAttributes: Record<string, string[]>;
  quantityStep: number;
  quote: Quote | null;
  rebates: {
    couponLink: string | null;
    description: string;
    legalLink: string | null;
    price: number;
    text: string;
    title: string;
    validDate: string | null;
  }[];
  rewardPoints: RewardPoints | null;
  sale: boolean;
  shopRetailPrice: number | null;
  sortHash: string;
  specialOrder: boolean;
  specificMarketingDescriptions: {
    code: string;
    label: string;
    value: string[];
  }[];
  sponsorType: 'NONE' | 'SPONSORED' | 'POTENTIALLY_SPONSORED';
  stockMessage: Record<string, string> | null;
  storeId: number | null;
  supplierId: number | null;
  title: string;
  variations: {
    attributes: RawAttribute[];
    notes: string[][];
    partTitle: string | null;
    variationId: string;
  }[];
  vehicleId: number | null;
  warnings: WarningAsset[];
};

export type ProductUrlParams = {
  credentialId: number | null;
  linecard: number;
  part_term: number | null;
  partnumberid: string;
  supplierId: number | null;
  vehicle?: number;
  vin?: string | null;
};

export type Quantity = {
  type: 'EQ' | 'GT';
  value: number;
};

export type Quote = {
  action: 'ADD_TO_CART' | 'CHECK_AVAILABILITY' | 'CALL_AVAILABILITY' | 'CALL_SUPPLIER' | 'HIDDEN';
  availability: {
    lines:
      | {
          address: string | null;
          group: string | null;
          id: number | null;
          name: string;
          qty: Quantity;
          type: 'MAIN' | 'NETWORK';
        }[]
      | null;
    total: Quantity | null;
    type: 'AVAILABILITY_DETAILED' | 'AVAILABILITY_TOTAL' | 'AVAILABILITY_BOTH';
  } | null;
  status: 'IN_STOCK' | 'SPECIAL_ORDER' | 'BACK_ORDER' | 'OUT_OF_STOCK';
};

export type RawAttribute = {
  name:
    | 'Aspiration'
    | 'BedLength'
    | 'Bedtype'
    | 'Bodynumdoors'
    | 'Bodytype'
    | 'Brakeabs'
    | 'Brakesystem'
    | 'CCA'
    | 'CylinderHeadType'
    | 'IncludesHardware'
    | 'Material'
    | 'Drivetype'
    | 'Diameter'
    | 'Enginedesignation'
    | 'EngineMfr'
    | 'EngineVersion'
    | 'EngineVIN'
    | 'Viscosity'
    | 'FrontBrakeType'
    | 'Frontspring'
    | 'FuelDeliverySubType'
    | 'FuelDeliveryType'
    | 'FuelSystemControlType'
    | 'FuelSystemDesign'
    | 'Fueltype'
    | 'IgnitionSystemType'
    | 'ItemQuantity'
    | 'MfrBodyCode'
    | 'Position'
    | 'PowerOutputHP'
    | 'PowerOutputKP'
    | 'Qty'
    | 'RearBrakeType'
    | 'Rearspring'
    | 'Refurbished'
    | 'Region'
    | 'Remanufactured'
    | 'SteeringSystem'
    | 'SteeringType'
    | 'TiresApplication'
    | 'TiresCategory'
    | 'TiresCategoryLabel'
    | 'TiresCommercialPosition'
    | 'TiresKilometerRating'
    | 'TiresLoadIndex'
    | 'TiresLoadIndexDual'
    | 'TiresLoadRange'
    | 'TiresMileageRating'
    | 'TiresOEM'
    | 'TiresPosition'
    | 'TireTreadDepth'
    | 'TiresQuality'
    | 'TiresRunFlat'
    | 'TiresSeasons'
    | 'TiresSideWallStyle'
    | 'TiresSize'
    | 'TireSpecialNote'
    | 'TiresSpeedRating'
    | 'TiresSupplier'
    | 'TireType'
    | 'TireWinterStud'
    | 'TransmissionCode'
    | 'TransmissionControlTypeId'
    | 'TransmissionElectronicallyControlled'
    | 'TransmissionMfr'
    | 'TransmissionNumberofSpeeds'
    | 'TransmissionType'
    | 'ValvesPerEngine'
    | 'WarrantyDistance'
    | 'WarrantyHasSpecial'
    | 'WarrantySpecial'
    | 'WarrantyTime'
    | 'WinterCertification'
    | 'Wheelbase';
  value: string[];
};

export type RewardPoints = {
  bonus: number;
  defaultPoints: number;
  isPromotion: boolean;
  multipliers: number;
  points: number;
  program: string;
};

export type Shipment = {
  PONumber: string | null;
  additionalFields?: {
    default: string | null;
    label: string;
    name: string;
    options:
      | {
          text: string;
          value: string;
        }[]
      | null;
    required: boolean;
    type: 'text' | 'select';
  }[];
  billingAddress: Address;
  credentialsId: number | null;
  customNotes: string | null;
  customerNumber: string;
  discountTotal: number;
  errors: string[];
  freightTerm: string;
  freightTerms: {
    name: string;
    service: FreightTermGroup;
    services: FreightTermGroup[];
  }[];
  id: number;
  invoice: {
    customerNumber: string | null;
    id: number;
    lastError: string | null;
    shippingMethod: string;
    shortShippingMethod: string;
    status: number;
  };
  isPaid: boolean;
  isTaxFree: boolean;
  name: string;
  optionsPreferences: {
    shippingMethodCode: string | null;
  } | null;
  parts: ShipmentPart[];
  paymentValidation: {
    PONumberValidation: Validation[];
    noteValidation: Validation[];
  };
  paymentWay: 'creditCard' | 'wholesale';
  paymentWayOriginal: number;
  rewardPoints: number | null;
  shippingAddress: Address;
  storeId: number;
  supplierId: number;
  taxWithoutShipping: number;
};

export type ShipmentPart = {
  availableBundles: ShipmentPartAvailableBundle[];
  bundled: boolean;
  customNotes: string | null;
  error: string | null;
  id: number;
  labor: Labor;
  origin: 'UNDEFINED' | 'SEARCH' | 'STOCK_ORDER' | 'API';
  partTypeName: string | null;
  product: Product;
  quantity: number;
  rewardPoints: number | null;
  shipmentId: number;
  tax: number;
  totalDiscount: number;
  urlParams: ProductUrlParams;
  vehicleName: string | null;
};

export type ShipmentPartAvailableBundle = {
  description: string | null;
  expirationDate: string | null;
  id: string;
  name: string;
};

export type Store = {
  additionalInformation: CredentialsAdditionalInformation | null;
  address: Address;
  autoValidateCredentials: boolean;
  code: string | null;
  coordinates: Coordinates;
  credentials: CredentialField[];
  distance: number | null;
  dropship: boolean;
  email: string | null;
  hotshot: boolean;
  hotshotOutOfRange: boolean;
  id: number;
  logo: string | null;
  name: string | null;
  national: boolean;
  orderingSupported: boolean;
  phone: string | null;
  pickUp: boolean;
  servesLocation: boolean;
  supplierId: number;
  userNote: string | null;
  warehouse: boolean;
};

export type SubmittedLabor = {
  applicationId: string;
  duration?: number | null;
  orderItemId: number;
};

export type Supplier = {
  allowCustomNotes: boolean;
  allowCustomPurchaseNumber: boolean;
  allowOrder: boolean;
  allowPartLevelNotes: boolean;
  allowStockOrder: boolean;
  allowStoreSelect: boolean;
  autoValidateCredentials: boolean;
  checkoutMessage: string | null;
  comingSoon: boolean;
  connectionTime: 'NA' | 'LESS_ONE_MIN' | 'TODAY' | 'NEXT_DAY' | '1_PLUS_DAYS';
  coreChargePolicy: string | null;
  coreReturns: boolean;
  credentials: CredentialField[] | null;
  id: number;
  instructionUrl: string | null;
  locationHidden: boolean;
  logo: string | null;
  marketingDescription: string | null;
  name: string;
  newSupplierBadge: boolean;
  outageMessage?: string | null;
  outageType: 'NO_OUTAGE' | 'PARTIAL_OUTAGE' | 'FULL_OUTAGE';
  paid: boolean;
  registrationInstruction: string | null;
  returnPeriod: number | null;
  returnPolicy: string | null;
  slug: string;
  storeLocked: boolean;
  supportsApprove: boolean;
  tireConnect: boolean;
  tires: boolean;
  tutorialUrl: string | null;
  type: 'TIRES' | 'DEALER' | 'AFTERMARKET';
};

export type TireSize = {
  height?: number;
  loadIndex?: number | null;
  rim?: number;
  speedRating?: string | null;
  width?: number;
};

export type Validation = {
  max: number | null;
  message: string | null;
  min: number | null;
  regex: string | null;
};

export type Vehicle = {
  engineId: string;
  engineName: string;
  id: number;
  makeId: number;
  makeName: string;
  makePriority: number;
  modelId: number;
  modelName: string;
  regionIds: number[];
  submodelId: number;
  submodelName: string;
  vehicleTypeGroup: number;
  year: number;
};

export type WarningAsset = {
  File: string | null;
  FileIsImage: boolean | null;
  Type: string;
  Value: string;
};
