import { css } from '@emotion/react';
import { Box, Card, ImageFallback, Typography } from '@partstech/ui';
import { currency } from 'shared/lib/string';
import type { BundleDealItem as BundleItemType } from 'models';

const styles = {
  image: css`
    object-fit: contain;
  `,
};

export const BundleDealItem = ({ title, image, partNumber, quantity, price, discountPrice }: BundleItemType) => (
  <Box display="flex" justifyContent="space-between" data-testid="bundle">
    <Box display="flex">
      <Card p={1} height={16} width={16} flexShrink={0} borderColor="mono6">
        <ImageFallback src={image} height={56} width={56} css={styles.image} />
      </Card>
      <Box mx={2}>
        <Typography variant="subtitle2" color="black">
          {title}
        </Typography>
        <Typography variant="caption" color="black">
          {partNumber}
        </Typography>
        <Box display="flex" color="dark" mt={1}>
          <Box ml={1}>
            <Typography color="subtleText" variant="caption" lineThrough>
              {currency(price)}
            </Typography>
          </Box>
          <Box ml={1} textAlign="right">
            <Typography variant="subtitle1">{currency(discountPrice)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box>
      <Card
        background="white"
        borderColor="mono6"
        radius={100}
        width={10}
        height={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="subtitle2" bold>
          {quantity}
        </Typography>
      </Card>
    </Box>
  </Box>
);
