import { getFilesFromFormData } from '../file';
import { fileToBase64 } from './fileToBase64';

export const getImageBase64FromFormData = async (formData: FormData): Promise<string | null> => {
  const files = getFilesFromFormData(formData);

  const imageFiles = files.filter((file) => file.type.startsWith('image/'));

  if (imageFiles.length > 0) {
    const convertedFiles = await Promise.all(imageFiles.map(fileToBase64));

    return convertedFiles[0] ?? null;
  }

  return null;
};
