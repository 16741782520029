import { paymentApi } from '../paymentCards';
import { api as generatedApi } from './UpdatePaymentCard.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdatePaymentCard: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          paymentApi.util.updateQueryData('GetPaymentCards', undefined, ({ currentUser }) => {
            const card = currentUser?.activeMember?.shop?.cards?.find(({ id }) => id === input.cardId);

            if (card) {
              Object.assign(card, { expMonth: input.expMonth, expYear: input.expYear });
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: ['PaymentCard'],
    },
  },
});

export const { useUpdatePaymentCardMutation } = api;
