import { useEffect, useMemo } from 'react';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { FullStoryEventNames, useFullStory } from 'integrations/fullstory';
import { PartType } from 'models';
import type { ProductQueryParams } from 'types/product';

export const useFailedProductTracking = (isFailed: boolean, query?: ProductQueryParams) => {
  const { getAccountBySupplierId } = useSupplierAccounts();

  const supplierAccount = useMemo(() => {
    if (!query?.supplierId) {
      return null;
    }

    return getAccountBySupplierId(String(query.supplierId));
  }, [getAccountBySupplierId, query?.supplierId]);

  const { sendEvent } = useFullStory();

  useEffect(() => {
    if (isFailed && String(query?.part_term) === PartType.TIRE_ID) {
      sendEvent(FullStoryEventNames.FAILED_TIRE_FETCH, {
        supplier: supplierAccount?.supplier?.name,
        partNumberId: query?.partnumberid,
      });
    }
  }, [isFailed, query?.part_term, query?.partnumberid, sendEvent, supplierAccount?.supplier?.name]);
};
