/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../graphql';

export type CartLaborItemFragment = {
  __typename?: 'CartLaborItem';
  id: string;
  hours: Types.Scalars['Float']['input'];
  defaultHours: Types.Scalars['Float']['input'];
  vin?: Types.Scalars['String']['input'] | null;
  orderItemId?: string | null;
  name: Types.Scalars['String']['input'];
  notes?: Types.Scalars['String']['input'] | null;
  skill: Types.Scalars['String']['input'];
  identifiers?: { __typename?: 'Mitchell1Identifiers' } | { __typename?: 'MotorIdentifiers'; id: string } | null;
  vehicle?: { __typename?: 'Vehicle'; id: string } | null;
  taxonomy?: {
    __typename?: 'LaborTaxonomy';
    action?: Types.Scalars['String']['input'] | null;
    id: string;
    name: Types.Scalars['String']['input'];
  } | null;
};

export const CartLaborItemFragmentDoc = `
    fragment CartLaborItem on CartLaborItem {
  id
  identifiers {
    ... on MotorIdentifiers {
      id
    }
  }
  hours
  defaultHours
  vehicle {
    id
  }
  vin
  orderItemId
  name
  notes
  skill
  taxonomy {
    action
    id
    name
  }
}
    `;
