import type { Vehicle } from 'models';
import type { CreateRecentSearchInput } from 'shared/api';

/**
 * Implementing this interface adds possibility to be used
 * as a SearchEntryPoint
 *
 * TODO Move className, columnTitle, toString out to ~OptionInterface
 */
export interface SearchEntryPointInterface {
  getClassName?: () => string;
  getColumnTitle?: () => string;
  isVehicleRequired?: () => boolean;
  populateInput(): string;
  toString(): string;
  toRecentSearch?: (vehicle: Vehicle | null) => CreateRecentSearchInput;
}

export const isSearchEntryPoint = (model: object): model is SearchEntryPointInterface => 'populateInput' in model;
