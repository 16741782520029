import { isEmpty } from '@partstech/ui/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { shopApi } from 'shared/api';
import { useGetCurrentUser } from '../currentUser/useGetCurrentUser';
import type { GetFsStatsResponse } from 'shared/api/rest/gen/shop';

const injectedRtkApi = shopApi.injectEndpoints({
  endpoints: (build) => ({
    FsStats: build.query<GetFsStatsResponse, void>({
      query: () => ({ url: '/fs-stats', method: 'GET' }),
    }),
  }),
});

const { useFsStatsQuery } = injectedRtkApi;

export const useFsStats = () => {
  const { shop, isPrimary } = useGetCurrentUser();

  const { data, ...rest } = useFsStatsQuery(undefined, { skip: !isPrimary || isEmpty(shop) });

  const smsValidatedDate = useMemo(
    () => (data?.smsValidatedDate ? DateTime.fromISO(data.smsValidatedDate).toJSDate() : null),
    [data]
  );

  return { smsValidatedDate, ...rest };
};
