import { useMemo } from 'react';
import { CATALOG_GROUP } from 'constant';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { SupplierId } from 'types/supplier';
import { createFilters, getCheckedOptions } from 'utils';
import { useSupplierAccountFiltersMap } from '../supplierAccounts/useSupplierAccountFiltersMap';
import { useDynamicAttributes } from './useDynamicAttributes';
import { useSearchParams } from './useSearchParams';
import type { Product } from 'models';
import type { CheckedFilters, CreateFiltersConfig, Filter, MatchingSets } from 'types/search';

const emptyFilters: Filter[] = [];

export const useSearchFilters = (
  supplierAccountId: string | null,
  checkedFilters: Partial<CheckedFilters>,
  productsBySupplierAccountId: Product[],
  allProducts: Product[],
  matchingSets?: MatchingSets
) => {
  const {
    flags: { xlPartsTphEtasUi, availabilityFilterEnhancements },
  } = useLaunchDarkly();

  const { isTiresSearch } = useSearchParams();

  const { attributes } = useDynamicAttributes();
  const { accountsMap } = useSupplierAccountFiltersMap();

  const supplierParams = supplierAccountId ? accountsMap[Number(supplierAccountId)] : null;
  const supplierId = supplierParams?.supplierId ?? String(CATALOG_GROUP);
  const isTiresDisconnected = supplierParams?.isTiresDisconnected ?? false;

  const isTireSupplierWithGroupedAvailability =
    isTiresSearch &&
    ([SupplierId.ATD_TIRECONNECT, SupplierId.TIRE_HUB_TIRECONNECT].includes(Number(supplierId)) || isTiresDisconnected);

  const isPartSupplierWithGroupedAvailability =
    xlPartsTphEtasUi && [SupplierId.XL_PARTS, SupplierId.THE_PARTS_HOUSE].includes(Number(supplierId));

  const shouldUseStoreAvailability = isTireSupplierWithGroupedAvailability || isPartSupplierWithGroupedAvailability;

  const config: CreateFiltersConfig = useMemo(
    () => ({
      attributes,
      isAvailabilityFilterEnhancements: availabilityFilterEnhancements,
      isCatalog: supplierAccountId === `${CATALOG_GROUP}`,
      shouldUseMatchingSets: isTiresSearch,
      matchingSets,
      shouldUseStoreAvailability,
      supplierId,
    }),
    [
      attributes,
      availabilityFilterEnhancements,
      isTiresSearch,
      matchingSets,
      shouldUseStoreAvailability,
      supplierAccountId,
      supplierId,
    ]
  );

  const filters = useMemo(
    () =>
      supplierAccountId
        ? createFilters(productsBySupplierAccountId, getCheckedOptions(allProducts, checkedFilters, config), config)
        : emptyFilters,
    [supplierAccountId, productsBySupplierAccountId, allProducts, checkedFilters, config]
  );

  return {
    config,
    filters,
  };
};
