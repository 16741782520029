import { Card, ModalView, Typography } from '@partstech/ui';
import { currency } from 'shared/lib/string';
import { BundleDealItem } from './BundleDealItem';
import type { BundleDeal } from 'models';

type Props = {
  bundleDeal: BundleDeal | null;
  onClose?: () => void;
};

export const BundleDealModal = ({ bundleDeal, onClose }: Props) => {
  if (!bundleDeal) {
    return null;
  }

  return (
    <ModalView title="Bundle Deal" onClose={onClose}>
      <Typography variant="subtitle1">
        You've added eligible parts to your cart, creating a bundle just for you.
      </Typography>

      {bundleDeal?.items.map((item) => (
        <Card key={item.title} p={4} borderWidth={0} background="mono2" my={4}>
          <BundleDealItem {...item} />
        </Card>
      ))}

      <Typography variant="bodyText2">Original Price: {currency(bundleDeal?.originalPrice)}</Typography>

      <Typography variant="bodyText2">You Pay: {currency(bundleDeal.paymentPrice)}</Typography>

      <Typography variant="subtitle2" color="positive">
        Bundled Savings: {currency(bundleDeal.savingsAmount)}
      </Typography>
    </ModalView>
  );
};
