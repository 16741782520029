import { css } from '@emotion/react';
import { Box, Loader, Scrollbar, Typography } from '@partstech/ui';
import { ErrorBoundary } from 'app/ErrorBoundary';
import { useRecentSearches } from '../../hooks';
import { RecentSearchItem } from './RecentSearchItem';
import type { Theme } from '@partstech/ui';
import type { RecentSearchInterface } from 'features/recentSearches';

const styles = {
  loader: (theme: Theme) => css`
    ${theme.breakpoints.down('md')} {
      display: contents;
    }
  `,
  scrollbar: (theme: Theme) => css`
    max-height: ${theme.sizing(120)};
    width: 100%;

    ${theme.breakpoints.down('md')} {
      max-height: -webkit-fill-available;
    }
  `,
};

type Props = {
  onSelect?: (recentSearch: RecentSearchInterface) => void;
};

const List = ({ onSelect, ...props }: Props) => {
  const { recentSearches, selectRecentSearch, isLoading } = useRecentSearches({ onSelect });

  if (isLoading) {
    return (
      <Box height={70} css={styles.loader}>
        <Loader isCentered />
      </Box>
    );
  }

  if (recentSearches.length === 0) {
    return (
      <Box textAlign="center" py={12} {...props}>
        <Typography color="dark">Search history is empty</Typography>
      </Box>
    );
  }

  return (
    <Scrollbar css={styles.scrollbar} className="ps-rv" {...props}>
      {recentSearches.map((recentSearch) => (
        <RecentSearchItem key={recentSearch.id} recentSearch={recentSearch} onSelect={selectRecentSearch} />
      ))}
    </Scrollbar>
  );
};

export const RecentSearchesList = (props: Props) => (
  <ErrorBoundary>
    <List {...props} data-testid="recentSearches" />
  </ErrorBoundary>
);
