/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { CartOrderItemFragmentDoc } from './CartOrderItemFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CartOrderFragment = {
  __typename?: 'CartOrder';
  id: string;
  purchaseOrderNumber?: Types.Scalars['String']['input'] | null;
  customNotes?: Types.Scalars['String']['input'] | null;
  builtOrder?: {
    __typename?: 'BuiltOrder';
    coreChargeTotal: Types.Scalars['Float']['input'];
    discountTotal: Types.Scalars['Float']['input'];
    fetChargeTotal: Types.Scalars['Float']['input'];
    priceTotal: Types.Scalars['Float']['input'];
    shippingTotal: Types.Scalars['Float']['input'];
    paymentWay: Types.OrderPaymentWay;
    tax: Types.Scalars['Float']['input'];
    boschReward?: { __typename?: 'OrderBoschReward'; points: number } | null;
    bundles: Array<{
      __typename?: 'CartBundle';
      description?: Types.Scalars['String']['input'] | null;
      discount?: Types.Scalars['Float']['input'] | null;
      name: Types.Scalars['String']['input'];
      quantity: number;
      items: Array<{
        __typename?: 'CartBundleItem';
        discount: Types.Scalars['Float']['input'];
        orderItemId: string;
        requiredQuantity: number;
      }>;
    }>;
    errors: Array<{
      __typename?: 'OrderError';
      errorType: Types.OrderErrorType;
      message: Types.Scalars['String']['input'];
    }>;
    availableFreightTerms: Array<{
      __typename?: 'FreightTerm';
      name: Types.Scalars['String']['input'];
      frightTermService?: {
        __typename?: 'FreightTermService';
        charge: Types.Scalars['Float']['input'];
        methodCode?: Types.Scalars['String']['input'] | null;
      } | null;
    }>;
    selectedFrightTermService?: {
      __typename?: 'FreightTermService';
      methodCode?: Types.Scalars['String']['input'] | null;
    } | null;
    availableAdditionalFields?: Array<{
      __typename?: 'AvailableAdditionalFields';
      default?: Types.Scalars['String']['input'] | null;
      label: Types.Scalars['String']['input'];
      name: Types.Scalars['String']['input'];
      required: Types.Scalars['Boolean']['input'];
      type: Types.Scalars['String']['input'];
      options?: Array<{
        __typename?: 'AvailableAdditionalFieldOption';
        text: Types.Scalars['String']['input'];
        value: Types.Scalars['String']['input'];
      }> | null;
    }> | null;
    customNotesValidation?: {
      __typename?: 'OrderFieldValidation';
      maxLength?: number | null;
      minLength?: number | null;
      regex?: Types.Scalars['String']['input'] | null;
      message?: Types.Scalars['String']['input'] | null;
    } | null;
    purchaseOrderNumberValidation?: {
      __typename?: 'OrderFieldValidation';
      maxLength?: number | null;
      minLength?: number | null;
      regex?: Types.Scalars['String']['input'] | null;
      message?: Types.Scalars['String']['input'] | null;
    } | null;
  } | null;
  additionalFields?: Array<{
    __typename?: 'OrderAdditionalField';
    name: Types.Scalars['String']['input'];
    value: Types.Scalars['String']['input'];
  }> | null;
  optionsPreferences?: {
    __typename?: 'SupplierOptionsPreferences';
    shippingMethodCode?: Types.Scalars['String']['input'] | null;
  } | null;
  account?: {
    __typename?: 'Account';
    id: string;
    supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
  } | null;
  items?: Array<{
    __typename?: 'CartOrderItem';
    id: string;
    image?: Types.Scalars['String']['input'] | null;
    partName: Types.Scalars['String']['input'];
    partNumber: Types.Scalars['String']['input'];
    partNumberId: Types.Scalars['String']['input'];
    lineCardId?: number | null;
    origin: Types.CartOrderItemOrigin;
    quantity: number;
    tireSize?: Types.Scalars['String']['input'] | null;
    vin?: Types.Scalars['String']['input'] | null;
    builtItem?: {
      __typename?: 'BuiltItem';
      sponsorType: Types.OrderItemSponsorType;
      bundled: Types.Scalars['Boolean']['input'];
      discount: Types.Scalars['Float']['input'];
      errors: Array<{ __typename?: 'OrderItemError'; message: Types.Scalars['String']['input'] }>;
      availableMotorLaborApplications?: Array<{
        __typename?: 'CartLabor';
        duration: Types.Scalars['BigDecimal']['input'];
        id: string;
        name?: Types.Scalars['String']['input'] | null;
        notes?: Array<Types.Scalars['String']['input']> | null;
        parentId?: string | null;
        skillCode: Types.Scalars['String']['input'];
        taxonomyName: Types.Scalars['String']['input'];
        taxonomyAction?: Types.Scalars['String']['input'] | null;
        taxonomyId: string;
        baseLaborTimeDescription?: Types.Scalars['String']['input'] | null;
        descriptions?: Array<Types.Scalars['String']['input']> | null;
        position?: { __typename?: 'Position'; name: Types.Scalars['String']['input'] } | null;
        attributes?: Array<{
          __typename?: 'Attribute';
          name?: Types.Scalars['String']['input'] | null;
          text?: Types.Scalars['String']['input'] | null;
        }> | null;
      }> | null;
      availableBundles: Array<{
        __typename?: 'AvailableBundle';
        description?: Types.Scalars['String']['input'] | null;
        expirationDate?: Types.Scalars['String']['input'] | null;
        id: Types.Scalars['String']['input'];
        name: Types.Scalars['String']['input'];
      }>;
      product?: {
        __typename?: 'OrderItemProduct';
        price: Types.Scalars['Float']['input'];
        customerPrice?: Types.Scalars['Float']['input'] | null;
        coreCharge?: Types.Scalars['Float']['input'] | null;
        fetCharge?: Types.Scalars['Float']['input'] | null;
        mapPrice?: Types.Scalars['Float']['input'] | null;
        listPrice?: Types.Scalars['Float']['input'] | null;
        backOrderable?: Types.Scalars['Boolean']['input'] | null;
        specialOrder?: Types.Scalars['Boolean']['input'] | null;
        partNumberDisplay: Types.Scalars['String']['input'];
        availabilityLines: Array<{
          __typename?: 'OrderItemProductAvailabilityLine';
          address?: Types.Scalars['String']['input'] | null;
          group?: Types.Scalars['String']['input'] | null;
          id?: number | null;
          name: Types.Scalars['String']['input'];
          quantity: number;
          type: Types.OrderItemProductAvailabilityLineType;
        }>;
        attributes: Array<{
          __typename?: 'OrderItemProductAttribute';
          name?: Types.Scalars['String']['input'] | null;
          value: Array<Types.Scalars['String']['input']>;
        }>;
        notes: Array<{ __typename?: 'OrderItemProductNotes'; notes: Array<Types.Scalars['String']['input']> }>;
        deliveryNotes: Array<{
          __typename?: 'OrderItemProductDeliveryNotes';
          label?: Types.Scalars['String']['input'] | null;
          text?: Types.Scalars['String']['input'] | null;
        }>;
        warnings: Array<
          | { __typename?: 'OrderItemProductAttachmentWarning'; attachment: Types.Scalars['String']['input'] }
          | { __typename?: 'OrderItemProductImageWarning'; path: Types.Scalars['String']['input'] }
          | { __typename?: 'OrderItemProductTextWarning'; text: Types.Scalars['String']['input'] }
        >;
      } | null;
      boschReward?: { __typename?: 'OrderBoschReward'; points: number } | null;
    } | null;
    brand?: {
      __typename?: 'Brand';
      logo?: Types.Scalars['String']['input'] | null;
      name: Types.Scalars['String']['input'];
    } | null;
    partType?: {
      __typename?: 'PartType';
      id: string;
      name: Types.Scalars['String']['input'];
      category?: { __typename?: 'PartCategory'; name: Types.Scalars['String']['input'] } | null;
    } | null;
    vehicle?: { __typename?: 'Vehicle'; id: string } | null;
    laborItems?: Array<{
      __typename?: 'CartLaborItem';
      id: string;
      hours: Types.Scalars['Float']['input'];
      defaultHours: Types.Scalars['Float']['input'];
      vin?: Types.Scalars['String']['input'] | null;
      orderItemId?: string | null;
      name: Types.Scalars['String']['input'];
      notes?: Types.Scalars['String']['input'] | null;
      skill: Types.Scalars['String']['input'];
      identifiers?: { __typename?: 'Mitchell1Identifiers' } | { __typename?: 'MotorIdentifiers'; id: string } | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
      taxonomy?: {
        __typename?: 'LaborTaxonomy';
        action?: Types.Scalars['String']['input'] | null;
        id: string;
        name: Types.Scalars['String']['input'];
      } | null;
    }> | null;
  }> | null;
};

export const CartOrderFragmentDoc = `
    fragment CartOrder on CartOrder {
  id
  builtOrder {
    coreChargeTotal
    discountTotal
    boschReward {
      points
    }
    bundles {
      description
      discount
      items {
        discount
        orderItemId
        requiredQuantity
      }
      name
      quantity
    }
    errors {
      errorType
      message
    }
    fetChargeTotal
    priceTotal
    shippingTotal
    paymentWay
    availableFreightTerms {
      name
      frightTermService {
        charge
        methodCode
      }
    }
    selectedFrightTermService {
      methodCode
    }
    tax
    availableAdditionalFields {
      default
      label
      name
      required
      type
      options {
        text
        value
      }
    }
    customNotesValidation {
      maxLength
      minLength
      regex
      message
    }
    purchaseOrderNumberValidation {
      maxLength
      minLength
      regex
      message
    }
  }
  additionalFields {
    name
    value
  }
  optionsPreferences {
    shippingMethodCode
  }
  purchaseOrderNumber
  customNotes
  account {
    id
    supplier {
      id
      name
    }
  }
  items {
    ...CartOrderItem
  }
}
    ${CartOrderItemFragmentDoc}`;
