import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  line: (theme: Theme) => css`
    :hover {
      background: ${theme.color.lightBlue};
      border-radius: ${theme.sizing(2)};
      margin: ${theme.sizing(1, -1, 0, -1)};
      padding: ${theme.sizing(0, 1, 0, 2)};
    }
  `,
};
