export const getFilesFromFormData = (formData: FormData): File[] => {
  const files: File[] = [];

  formData.forEach((value) => {
    if (value instanceof File) {
      files.push(value);
    }
  });

  return files;
};
