import { css } from '@emotion/react';
import { Box, Skeleton } from '@partstech/ui';
import { useState } from 'react';
import type { Theme } from '@emotion/react';

const styles = {
  container: css`
    aspect-ratio: 16/9;
  `,
  video: (isLoading: boolean) => (theme: Theme) => css`
    display: ${isLoading ? 'none' : 'block'};
    border: 0;
    width: 100%;
    height: 100%;
    border-radius: ${theme.sizing(2)};
    position: absolute;
    top: 0;
    left: 0;
  `,
  loading: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
};

type Props = {
  src?: string;
};

export const SequelVideoPlayer = ({ src }: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  if (!src) {
    return (
      <Box position="relative" width="100%" css={styles.container}>
        <Skeleton height="100%" width="100%" css={styles.loading} />
      </Box>
    );
  }

  return (
    <Box position="relative" width="100%" css={styles.container}>
      {isLoading && <Skeleton height="100%" width="100%" css={styles.loading} />}

      <iframe
        src={src}
        title="Sequel video player"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        css={styles.video(isLoading)}
        onLoad={handleLoad}
      />
    </Box>
  );
};
