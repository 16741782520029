import { DEFAULT_AVAILABILITY, Routes } from 'constant';
import { pickDefinedValues } from 'shared/lib/object';
import { SearchTypes } from 'types/search';
import { generateUrl } from './generateUrl';
import type { SearchParams, SearchUrlBySearchValues } from 'types/search';

export const getSearchUrl = (query: SearchParams) => {
  const params = {
    ...pickDefinedValues(query),
    availability: query.availability ?? DEFAULT_AVAILABILITY,
  };

  return generateUrl(Routes.SEARCH_RESULTS, {}, params);
};

export const getSearchUrlBySearchValues = ({
  searchValues,
  selectedSupplier,
  dealers,
  jobId,
  step,
  taskType: task_type,
  sort,
  availability,
  prefill,
}: SearchUrlBySearchValues) => {
  const {
    vehicleId,
    vin,
    plate,
    state,
    partText,
    partTextId,
    partNumber,
    manufacturers,
    tireId,
    tiresSize,
    partTypes,
    type,
    attributes,
    parts,
    ...values
  } = searchValues;

  const params: SearchParams = {
    vehicle: vehicleId ?? undefined,
    vin: vin ?? undefined,
    plate: plate ?? undefined,
    state: state ?? undefined,
    part_text: partText ?? undefined,
    part_text_id: partTextId ?? undefined,
    partnumber: partNumber ?? undefined,
    manufacturers: manufacturers ?? undefined,
    tire_id: tireId ? String(searchValues.tireId) : undefined,
    tire_sizes: tiresSize ?? undefined,
    part_types: partTypes?.join(','),
    type: type === SearchTypes.TIRES ? SearchTypes.TIRES : undefined,
    selected_distributor: selectedSupplier ?? undefined,
    job_id: jobId,
    attributes: attributes ?? undefined,
    dealers: dealers && type !== SearchTypes.TIRES ? 1 : undefined,
    step: step ? String(step) : undefined,
    task_type: task_type ?? undefined,
    sort: sort ?? undefined,
    parts,
    prefill,
    ...values,
    availability,
  };

  return getSearchUrl(params);
};
