import { useCallback } from 'react';
import { useBroadcastChannel } from 'app/BroadcastChannelProvider/BroadcastChannelContext';
import { useMutationCallback } from 'shared/api';
import { useLogoutMutation } from 'store/mutations/logout';

export const useLogout = () => {
  const { sendEvent } = useBroadcastChannel();

  const successLogout = useCallback(() => sendEvent('logout'), [sendEvent]);

  const [logoutTrigger, status] = useMutationCallback(useLogoutMutation, { onSuccess: successLogout });

  const logout = useCallback(() => logoutTrigger(), [logoutTrigger]);

  return [logout, status] as const;
};
