import { useForm } from '@partstech/ui/forms';
import { isEmpty } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { createPaymentCardFromData } from 'entities/paymentCard';
import { getYearAddedCentury } from 'shared/lib/dates';
import { useFormMutationCallback } from 'shared/lib/form';
import { useChangePaymentCardMutation } from '../../../../api/changeCard';
import { useUpdatePaymentCardMutation } from '../../../../api/updateCard';
import type { PaymentCard } from 'entities/paymentCard';

type FormData = {
  holder: string;
  cardNumber: string;
  expire: string;
  isMovingSubscriptions: boolean;
};

export type UseEditCreditCardPayload = {
  card: PaymentCard;
  onSubmit?: (card: PaymentCard, isUpdatedPaymentMethod: boolean) => void;
};

export const useEditCreditCardForm = ({ card, onSubmit }: UseEditCreditCardPayload) => {
  const canRemove = card.isRemovable();
  const isChangePaymentDisabled = !canRemove;

  const form = useForm<FormData>({
    defaultValues: {
      expire: `${card.expMonth > 9 ? card.expMonth : `0${card.expMonth}`} / ${`${card.expYear}`.slice(-2)}`,
      isMovingSubscriptions: !canRemove,
    },
    mode: 'onChange',
  });

  const { formState, watch, setError } = form;
  const { isSubmitting, isValid, dirtyFields } = formState;

  const isMovingSubscriptions = watch('isMovingSubscriptions');

  const isChangePaymentMethod = isMovingSubscriptions && !isChangePaymentDisabled;

  const successMessage = !isChangePaymentMethod ? 'Credit card updated' : undefined;

  const [updatePaymentCard] = useFormMutationCallback(useUpdatePaymentCardMutation, {
    successMessage,
    onValidationError: setError,
  });
  const [changePaymentCard] = useFormMutationCallback(useChangePaymentCardMutation, {
    successMessage,
    onValidationError: setError,
  });

  const submitForm = useCallback(
    async (values: FormData) => {
      const { expire } = values;

      const [expMonth = card.expMonth, expYear = card.expYear] = expire.split(' / ').map(Number);

      if (dirtyFields.expire) {
        await updatePaymentCard({
          input: { cardId: card.id ?? '', expMonth, expYear: getYearAddedCentury(expYear) },
        });
      }

      if (isChangePaymentMethod && canRemove) {
        await changePaymentCard({ input: { cardId: card.id ?? '' } });
      }

      onSubmit?.(createPaymentCardFromData({ ...card, expMonth, expYear }), isChangePaymentMethod);
    },
    [card, dirtyFields.expire, isChangePaymentMethod, canRemove, onSubmit, updatePaymentCard, changePaymentCard]
  );

  return {
    card,
    form,
    submitForm,
    isLoading: isSubmitting,
    isDisabled: isSubmitting || !isValid || isEmpty(dirtyFields),
    isChangePaymentDisabled,
  };
};
