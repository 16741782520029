import { createSelector, createSlice } from '@reduxjs/toolkit';
import { CHECK_STATUS_REQUEST_LIMIT } from 'constant';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

const selectState = (state: RootState) => state.entities.supplierAccounts;

export const selectTotalRequestsLeft = createSelector(selectState, (state) => state.totalRequestsLeft);

export const selectIsCheckStatusPending = createSelector(selectState, (state) => state.isCheckStatusPending);

export const selectLastAddedId = createSelector([selectState], (state) => state.lastAddedId);

type InitialState = {
  /**
   * Pause a long polling
   */
  isCheckStatusPending: boolean;
  /**
   * Active supplier in the carousel on the search page
   */
  selectedId: string | null;
  /**
   * Number of requests for a long polling
   */
  totalRequestsLeft: number;
  /**
   * Scroll to the last added supplier on the add supplier page
   */
  lastAddedId: string | null;
};

const initialState: InitialState = {
  isCheckStatusPending: false,
  selectedId: null,
  totalRequestsLeft: CHECK_STATUS_REQUEST_LIMIT,
  lastAddedId: null,
};

const supplierAccountsSlice = createSlice({
  name: 'supplierAccounts',
  initialState,
  reducers: {
    changePauseRequest: (state, action: PayloadAction<boolean>) => {
      state.isCheckStatusPending = action.payload;
    },
    setRequestCount: (state, action: PayloadAction<number>) => {
      state.totalRequestsLeft = action.payload;
    },
    setLastAddedId: (state, action: PayloadAction<string | null>) => {
      state.lastAddedId = action.payload;
    },
  },
});

export const supplierAccounts = supplierAccountsSlice.reducer;
export const { changePauseRequest, setRequestCount, setLastAddedId } = supplierAccountsSlice.actions;
