import { entries } from '@partstech/ui/utils';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useCallback, useMemo } from 'react';
import { useSupplierSelector } from 'components/SupplierSelector/SupplierSelectorContext';
import { CATALOG_GROUP } from 'constant';
import { useProductResults } from 'store/queries/searchResults/useProductResults';
import { applyFiltersToProduct, getMatchingSets, getTotals } from 'utils';
import { usePopulateFiltersFromQuery } from '../search/usePopulateFiltersFromQuery';
import { useSearchFilters } from '../search/useSearchFilters';
import { useSearchParams } from '../search/useSearchParams';
import { useSupplierAccounts } from '../supplierAccounts/useSupplierAccounts';
import type { Product } from 'models';
import type { SearchResultsContextValue } from 'pages/SearchResults/SearchResultsContext';
import type { SearchErrorData } from 'store/features/search/settings';
import type { MatchingSets } from 'types/search';

const getSupplierAccountIdsByStatus = (
  results: Record<
    string,
    {
      status: QueryStatus;
    }
  >,
  statuses: QueryStatus[]
) =>
  entries(results)
    .filter(([_, value]) => statuses.includes(value.status))
    .map(([id]) => Number(id));

const emptyProducts: Product[] = [];
const emptyErrors: string[] = [];

export const useSearchResults = (): SearchResultsContextValue => {
  const { isTiresSearch } = useSearchParams();

  const { selectedSupplierAccountId } = useSupplierSelector();

  const { values: checkedFilters } = usePopulateFiltersFromQuery();

  const { productResults } = useProductResults();

  const { accounts } = useSupplierAccounts();

  const loadingIds = useMemo(
    () => getSupplierAccountIdsByStatus(productResults, [QueryStatus.pending]),
    [productResults]
  );

  const loadedIds = useMemo(
    () => getSupplierAccountIdsByStatus(productResults, [QueryStatus.fulfilled, QueryStatus.rejected]),
    [productResults]
  );

  const { hasAccounts: hasAccountsByType } = useSupplierAccounts(
    { type: isTiresSearch ? 'tires' : 'parts' },
    { pollingInterval: loadingIds.length > 0 ? 10000 : undefined }
  );

  const matchingSets = useMemo(
    () =>
      isTiresSearch
        ? entries(productResults).reduce<Record<number, MatchingSets>>(
            (memo, [supplierAccountId, { products }]) => ({
              ...memo,
              [supplierAccountId]: getMatchingSets(products),
            }),
            {}
          )
        : {},
    [isTiresSearch, productResults]
  );

  const { config, filters } = useSearchFilters(
    selectedSupplierAccountId,
    checkedFilters,
    productResults[selectedSupplierAccountId ?? 0]?.products ?? emptyProducts,
    entries(productResults).flatMap(([_, { products }]) => products),
    selectedSupplierAccountId ? matchingSets[Number(selectedSupplierAccountId)] : undefined
  );

  const filteredProductsBySupplierAccountId = useMemo(
    () =>
      entries(productResults).reduce<Record<string, Product[]>>((memo, [supplierAccountId, { products }]) => {
        if (loadingIds.includes(Number(supplierAccountId))) {
          return memo;
        }

        return {
          ...memo,
          [supplierAccountId]: products.filter((product) =>
            applyFiltersToProduct(checkedFilters, {
              ...config,
              isCatalog: supplierAccountId === `${CATALOG_GROUP}`,
              matchingSets: matchingSets[Number(supplierAccountId)],
            })(product, products)
          ),
        };
      }, {}),
    [checkedFilters, config, loadingIds, matchingSets, productResults]
  );

  const productsBySupplierAccountId = useMemo(() => {
    if (!selectedSupplierAccountId) {
      return emptyProducts;
    }

    return productResults[selectedSupplierAccountId]?.products ?? emptyProducts;
  }, [productResults, selectedSupplierAccountId]);

  const getErrorsBySupplierAccountId = useCallback(
    (supplierAccountId: string | null) =>
      supplierAccountId ? (productResults[supplierAccountId]?.errors ?? emptyErrors) : emptyErrors,
    [productResults]
  );

  const getErrorsMap = useCallback(
    () =>
      entries(productResults).reduce<Record<string, SearchErrorData>>((acc, [supplierAccountId, { errors }]) => {
        if (errors && errors.length > 0) {
          const supplierAccount = accounts.find((account) => account.id === supplierAccountId);

          if (!supplierAccount) {
            return acc;
          }

          const { store, supplier } = supplierAccount;

          const storeAddress = store?.address?.address1 ?? store?.address?.address2 ?? '';

          return {
            ...acc,
            [supplierAccountId]: {
              errorMessages: errors,
              supplierName: supplier?.name ?? '',
              storeAddress,
            },
          };
        }

        return acc;
      }, {}),
    [accounts, productResults]
  );

  const totals = useMemo(() => getTotals(filteredProductsBySupplierAccountId), [filteredProductsBySupplierAccountId]);

  const isAllLoaded = useMemo(() => {
    if (!hasAccountsByType) {
      return true;
    }

    return loadingIds.length === 0 && loadedIds.length > 0;
  }, [hasAccountsByType, loadedIds.length, loadingIds.length]);

  const contextValue: SearchResultsContextValue = useMemo(
    () => ({
      checkedFilters,
      getErrorsBySupplierAccountId,
      getErrorsMap,
      filters,
      totals,
      filteredProducts: selectedSupplierAccountId
        ? (filteredProductsBySupplierAccountId[selectedSupplierAccountId] ?? emptyProducts)
        : emptyProducts,
      products: productsBySupplierAccountId,
      loadedIds,
      loadingIds,
      isAllLoaded,
      isActiveSupplierAccountLoaded: selectedSupplierAccountId
        ? loadedIds.includes(Number(selectedSupplierAccountId))
        : false,
      isCatalogLoaded: loadedIds.includes(CATALOG_GROUP),
    }),
    [
      checkedFilters,
      getErrorsBySupplierAccountId,
      getErrorsMap,
      filters,
      totals,
      selectedSupplierAccountId,
      filteredProductsBySupplierAccountId,
      productsBySupplierAccountId,
      loadedIds,
      loadingIds,
      isAllLoaded,
    ]
  );

  return contextValue;
};
