import type { FilterOption } from 'types/search';

export const createOption = (
  value: FilterOption['value'],
  name: FilterOption['name'],
  count?: FilterOption['count'],
  type?: FilterOption['type'],
  label?: FilterOption['label'],
  tooltip?: FilterOption['tooltip']
): FilterOption => ({
  name,
  value,
  count,
  type,
  label,
  tooltip,
});
