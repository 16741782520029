import { MemberInvitation } from 'models';
import { createMemberPermissionsFromData } from './createMemberPermissionsFromData';
import type { InvitationStatus, MemberPermissions } from 'shared/api';

type Invitation = {
  id: string;
  email: string;
  sentDate: string;
  status: InvitationStatus;
  permissions?: MemberPermissions | null;
};

export const createMemberInvitationFromData = (userInvitation: Invitation) => {
  const permissions = createMemberPermissionsFromData(userInvitation.permissions ?? undefined);

  return new MemberInvitation({ ...userInvitation, permissions });
};
