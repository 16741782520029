import { useEffect } from 'react';
import { useInputValue, type UseInputValueOptions } from 'shared/lib/form';
import type { SearchEntryPointInterface } from 'features/searchForm';

export const useSearchFormInputValue = (value?: SearchEntryPointInterface | null, options?: UseInputValueOptions) => {
  const {
    value: inputValue,
    changeValue: changeInputValue,
    clearValue,
  } = useInputValue(value?.populateInput() ?? '', options);

  useEffect(() => {
    changeInputValue(value?.populateInput() ?? '');
  }, [changeInputValue, value]);

  return {
    value: inputValue,
    changeValue: changeInputValue,
    clearValue,
  };
};
