import { client, type Config } from './client';
import type { ResponseError } from './errors/ResponseError';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';

type AxiosArgs =
  | {
      body: unknown;
      headers?: Config['headers'];
      method: 'POST' | 'PUT';
      params?: Config['params'];
      responseType?: Config['responseType'];
      url: string;
    }
  | {
      body?: never;
      headers?: Config['headers'];
      method: 'DELETE';
      params?: never;
      responseType?: Config['responseType'];
      url: string;
    }
  | {
      body?: never;
      headers?: Config['headers'];
      method: 'GET';
      params?: Config['params'];
      responseType?: Config['responseType'];
      url: string;
    };

export const axiosBaseQuery =
  ({ baseUrl = '' }: { baseUrl?: string }): BaseQueryFn<AxiosArgs, unknown, ResponseError> =>
  async ({ body, headers, method, params, responseType, url }) => {
    try {
      const result = await client({ data: body, headers, method, params, responseType, url: baseUrl + url });

      return { data: result };
    } catch (axiosError) {
      const error = axiosError as ResponseError;

      return { error };
    }
  };
